:root {
  --main-text-color: #58595B;
  --color-hyperlink-green: #44e952;
  --green-mobile: #3fae2b;
  --color-green-d: #007500;
  --color-green: #00ad0f;
  --color-green-l: #40ae29;
  --color-gray: #444444;
  --color-gray-l: #565656;
  --color-red: #f44336;
  --color-light-red-background: rgba(244, 67, 54, 0.05);
  --color-red-l: #fdd9d7;
  --color-yellow: #ffdb00;
  --color-white: #ffffff;
  --color-gray-border: #979797;
  --color-gray-background: #f3f3f3;
  --font-robo-r: "Roboto-Regular";
  --font-robo-m: "Roboto-Medium";
  --font-robo-b: "Roboto-Bold";
  --font-robo-l: "Roboto-Light";
  --font-heavent: "DB Heavent";
  --font-adman: "DB Adman X";
  --scale-standard: .9;
  --vh: 67;
}

/* NOTES
  - Refs: https://dev.to/admitkard/mobile-issue-with-100vh-height-100-100vh-3-solutions-3nae
  There is an issue related to 100vh on mobile, it calculates the height not properly 
  and cause the layout not fully displayed 🙂

  Solution: Use the solution provided in the link.
  Summary: 
    - Set a variable named: --vh
    - On window onload: calculate and set --vh base on window.innerHeight to make sure 100% = 100vh
    - Replace everywhere using 100vh by calc(100 * var(--vh))
*/

html {
  width: 100%;
  height: calc(100 * var(--vh));
  font-family: var(--font-robo-r), sans-serif;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  position: relative;
  width: 100%;
  height: calc(100 * var(--vh));  
  min-height: calc(100 * var(--vh));
  margin: 0;
  padding: 0;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

figcaption,
figure,
main {
  display: block;
}

figure {
  margin: 1em 0;
}

hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style-type: none;
}
a {
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: 1px #767676 dotted;
  text-decoration: none;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: 700;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #eee;
  color: #222;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
  outline: unset;
}
input:focus::-webkit-input-placeholder {
  color: transparent !important;
}
input:focus:-moz-placeholder {
  color: transparent !important;
}
input:focus::-moz-placeholder {
  color: transparent !important;
}
input:focus:-ms-input-placeholder {
  color: transparent !important;
}

textarea {
  resize: none;
}
textarea:focus::-webkit-input-placeholder {
  color: transparent !important;
}
textarea:focus:-moz-placeholder {
  color: transparent !important;
}
textarea:focus::-moz-placeholder {
  color: transparent !important;
}
textarea:focus:-ms-input-placeholder {
  color: transparent !important;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  border: 1px solid #bbb;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}
.hide {
  display: none;
}
@font-face {
  font-family: 'SourceSansPro-Semibold';
  font-display: swap;
  src: url('../fonts/SourceSansPro-Semibold.eot');
  src: local('☺'), url('../fonts/SourceSansPro-Semibold.woff2') format('woff2'), url('../fonts/SourceSansPro-Semibold.ttf') format('truetype'), url('../fonts/SourceSansPro-Semibold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Regular';
  font-display: swap;
  src: url('../fonts/Roboto-Regular.eot');
  src: local('☺'), url('../fonts/Roboto-Regular.woff2') format('woff2'), url('../fonts/Roboto-Regular.ttf') format('truetype'), url('../fonts/Roboto-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Medium';
  font-display: swap;
  src: url('../fonts/Roboto-Medium.eot');
  src: local('☺'), url('../fonts/Roboto-Medium.woff2') format('woff2'), url('../fonts/Roboto-Medium.ttf') format('truetype'), url('../fonts/Roboto-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Light';
  font-display: swap;
  src: url('../fonts/Roboto-Light.eot');
  src: local('☺'), url('../fonts/Roboto-Light.woff2') format('woff2'), url('../fonts/Roboto-Light.ttf') format('truetype'), url('../fonts/Roboto-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Bold';
  font-display: swap;
  src: url('../fonts/Roboto-Bold.eot');
  src: local('☺'), url('../fonts/Roboto-Bold.woff2') format('woff2'), url('../fonts/Roboto-Bold.ttf') format('truetype'), url('../fonts/Roboto-Bold.svg') format('svg');
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Black';
  font-display: swap;
  src: url('../fonts/Roboto-Black.eot');
  src: local('☺'), url('../fonts/Roboto-Black.woff2') format('woff2'), url('../fonts/Roboto-Black.ttf') format('truetype'), url('../fonts/Roboto-Black.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ProximaNova-Black';
  font-display: swap;
  src: url('../fonts/ProximaNova-Black.eot');
  src: local('☺'), url('../fonts/ProximaNova-Black.woff2') format('woff2'), url('../fonts/ProximaNova-Black.ttf') format('truetype'), url('../fonts/ProximaNova-Black.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BebasNeue';
  font-display: swap;
  src: url('../fonts/BebasNeue.eot');
  src: local('☺'), url('../fonts/BebasNeue.woff2') format('woff2'), url('../fonts/BebasNeue.ttf') format('truetype'), url('../fonts/BebasNeue.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BebasNeue-Bold';
  font-display: swap;
  src: url('../fonts/BebasNeue Bold.eot');
  src: local('☺'), url('../fonts/BebasNeue Bold.woff2') format('woff2'), url('../fonts/BebasNeue Bold.ttf') format('truetype'), url('../fonts/BebasNeue Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DB Adman X';
  font-display: swap;
  src: url('../fonts/db-adman-x-v3.2.1.eot');
  src: local('☺'), url('../fonts/db-adman-x-v3.2.1.woff2') format('woff2'), url('../fonts/db-adman-x-v3.2.1.ttf') format('truetype'), url('../fonts/db-adman-x-v3.2.1.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DB Heavent Bd';
  font-display: swap;
  src: url('../fonts/db-havent-bd-v3.2.1.eot');
  src: local('☺'), url('../fonts/db-havent-bd-v3.2.1.woff2') format('woff2'), url('../fonts/db-havent-bd-v3.2.1.ttf') format('truetype'), url('../fonts/db-havent-bd-v3.2.1.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DB Heavent';
  font-display: swap;
  src: url('../fonts/db-heavent-v3.2.1.eot');
  src: local('☺'), url('../fonts/db-heavent-v3.2.1.woff2') format('woff2'), url('../fonts/db-heavent-v3.2.1.ttf') format('truetype'), url('../fonts/db-heavent-v3.2.1.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

body#th-site{
  line-height: 1;
  font-family: 'DB Adman X';
  font-size: 20px;
}
body#th-site .list-item li a,
body#th-site .title-list-tab .title-tab, body#th-site .menu-type-outside .cell-outside .button-menu-center, body#th-site .button-menu-bottom,
#th-site #service-area .location-checker .search .search-box .search-title,
#th-site .fare-estimator-form .title .title-left label,
#th-site .bottom-menu-list-2 .bottom-menu-item-2 svg text, #th-site .lh-business-program .bg-title,
#th-site .ajax-load-more-wrap.green .alm-load-more-btn.btn-loadmore,
#th-site .terms-conditions .title {
  font-family: 'DB Heavent';
  font-size: 30px;
}
#th-site .overlay .popup .header .price, #th-site .overlay .popup .header .title{
  font-size: 35px;
  line-height: 0.9;
  font-family: 'DB Heavent';
}
#th-site .fare-estimator-form,
#th-site .fare-estimator-form .text-box ,
#th-site .fare-estimator-form .btn-calculating button,
#th-site .fare-estimator-form .btn-destination .text-destination,
#th-site .intracity-home .fare-estimator-form .address input{
    font-family: 'DB Heavent';
    font-size: 21px;
}
#th-site .intracity-home .fare-estimator-form .service-types>.media-n-content .content,
#th-site .longhaul-home .fare-estimator-form .service-types>.media-n-content .content{
  font-family: 'DB Heavent';
}

body#th-site .list-item li a {
  font-size: 27px;
}
body#th-site .list-menu .btn-launch a,
body#th-site .bottom-info-meter-container .popout-info-meter, 
body#th-site .price-scale .price-start, body#th-site .price-end, body#th-site .price-road, 
body#th-site .vehicle-price .mini-box .row, body#th-site .c-select-flpr, body#th-site .vehicle-box p,
#th-site #service-area,
#service-area .location-checker textarea,
#th-site .intracity-home,
#th-site .overlay .popup .popup-text,
#th-site .result-popup-long-haul p
{
  font-family: 'DB Heavent';
  font-size: 20px;
}
#th-site .overlay .popup .booking-info table tr td:first-child{
  font-family: 'DB Heavent';
  font-size: 20px;
  font-weight: 500;
}
#th-site .overlay .popup .note
{
  font-family: 'DB Heavent';
  font-size: 18px;
  font-weight: 300;
}
#th-site .overlay .popup .footer a, #th-site  .overlay .popup .footer button{
  padding: 0 7px;
  line-height: 0.8;
  font-family: 'DB Heavent';
  font-size: 20px;
  font-weight: 400;
}
#th-site .overlay .popup .booking-info table{
  font-family: 'DB Heavent';
  font-size: 18px;
  font-weight: 400;
}
#th-site .vehicle-box p {
  font-weight: 500;
}
body#th-site .each-country .service-country a {
  font-family: 'DB Heavent';
  font-size: 39px;
  font-weight: 500;
  line-height: 35px;
}
body#th-site .txt-head .title , body#th-site .top-main-header .header .title{
  font-family: 'DB Heavent';
  font-size: 60px;
  font-weight: bold;
}
#th-site .omni-title {
  font-family: 'DB Heavent';
  font-size: 60px;
  font-weight: bold;
  line-height: 1;
}
#th-site .modal-popup-header h1, #th-site .modal-popup-header p {
  font-family: 'DB Heavent';
  font-size: 36px;
  line-height: 34px;
  margin: 10px 0;
}
#th-site .omni-description {
  font-family: 'DB Heavent';
  font-size: 30px;
}
#th-site .modal-popup-content {
  font-family: 'DB Adman X' !important;
  font-size: 22px;
  color: #212529;
}
#th-site .modal-popup-body h2, #th-site .modal-popup-body p {
  font-size: 22px;
  font-family: 'DB Adman X';
  color: #212529;
  margin-bottom: 8px;
}
#th-site .advantages .adv-feature h4{
  font-family: 'DB Adman X';
  font-size: 24px;
  line-height: 1.1
}
body#th-site .txt-head p, body#th-site .top-main-header .header-content {
  font-family: 'DB Heavent';
  font-size: 28px;
  line-height: 1.1
}
#th-site .lh-business-program .content-introduction{
  font-family: 'DB Adman X';
  font-size: 25px;
  line-height: 1.2;
}
body#th-site .post-loop .post-items p.title-post,
body#th-site,
body#th-site .extra-service .service-panel, body#th-site .business-program-city,
#th-site .lh-business-program,
#th-site .longhaul-home .fare-estimator-form .address input,
#th-site .terms-conditions{
  font-family: 'DB Adman X';
  font-size: 20px;
}
body#th-site .language-and-location {
  font-family: 'DB Heavent';
  font-size: 22px;
}
#th-site .advantages .adv-feature p{
  font-family: 'DB Adman X';
  font-size: 20px;
  line-height: 1;
}
body#th-site .bottom-menu-list .bottom-menu-item-1 p {
  font-weight: 500;
}

#th-site .business-program-city .bg-title,
#th-site .omnipage{
  line-height: 23px;
  font-family: 'DB Heavent';
  font-size: 30px;
}
#th-site .business-program-city .bg-title>div{
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#th-site .business-program-city .bg-title span:last-child{
  margin-bottom: -4px;
}
#th-site .business-program-city .extra-option .extra-content{
  font-size: 20px
}
#th-site .fare-estimator-form .main .item.working .working-message,
#th-site .fare-estimator-form .main .item.error .error-message{
  font-size: 15px;
}
#th-site .button-share-container .copied-success {
  font-family: 'DB Adman X';
  font-weight: 400;
  font-size: 20px;
}
#ph-site .business-program-city .extra-option .extra-content .extra-block >div{
  padding: 6px 0px;
}
img {
  max-width: 100%;
}
.site-branding img {
  height: auto;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  color: #fff;
  margin: 0;
}
.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6 {
  color: #58595B;
}

p {
  font-weight: 400;
  margin: 0;
}

a {
  text-decoration: none;
  color: #fff;
  margin: 0;
  font-weight: 100;
  cursor: pointer;
}
a.menu-item {
  text-transform: uppercase;
  margin-bottom: 3px;
  display: block;
}
input:focus {
  outline: none;
}
.hr {
  width: 30px;
  height: 3px;
  background: #fff;
  margin: 15px auto 15px auto;
  box-shadow: 1px 1px 2px #222;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
}
.flex-around {
  display: flex;
  justify-content: space-around;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.text-spacing-minus-2 {
  letter-spacing: -2px;
}
.content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.main-page {
  width: 100%;
  display: block;
  overflow: hidden;
  position: relative;
  transition: all 0s linear;
  height: calc(100 * var(--vh));
}
.main-page.expand-2 {
  min-height: calc(100vh - 80px);
  transition: all 0s linear;
}

.l-container {
  position: relative;
  width: auto;
  transition: all 0s linear;
  background-color: unset;
  height: 100%;
  background: url(https://www.deliveree.com/wp-content/uploads/2019/05/qbkls.png);
}
.l-container.push {
  margin-left: 240px;
  transition: all 0s linear;;
}

/****************************************/
/*           BUTTON MENU               */
/**************************************/
#button-menu-open {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -30px;
  z-index: 99;
  transition: all 0s ease;
  height: calc(100 * var(--vh));
  display: flex;
  align-items: center;
  z-index: -1;
}
#button-menu-open.collapse {
  right: 0px;
}

.menu-trigger .menu-content .triangle {
  width: 0;
  height: 0;
  border-top: 100px solid transparent;
  border-left: 95px solid #00ac2b;
  border-bottom: 100px solid transparent;
  cursor: pointer;
}
.menu-trigger .menu-content .triangle.collapse {
  border-left: 0;
  border-right: 0px solid transparent;
  cursor: pointer;
}

.menu.menu-type {
  position: fixed;
  top: 0px;
  left: -146px;
  width: 274px;
  height: calc(100 * var(--vh));
  z-index: 98;
  background-color: #00ac2b;
  background-size: cover;
  background-position: center;
  transition: all 0s linear;
}
.menu.open {
  background-color: transparent;
  left: 0;
  transition: all 0s linear;
}

.menu-type-outside {
  position: relative;
  z-index: 3;
  height: 100%;
}

/****************************************/
/*           CELL OUTSIDE              */
/**************************************/
.menu-type-outside .cell-outside {
  flex-wrap: wrap;
  width: 128px;
  position: absolute;
  right: 0;
  text-align: center;
  height: 100%;
  z-index: 1;
  opacity: 1;
  user-select: none;
}
.menu-type-outside .cell-outside a {
  transition: all 0s linear;
}

.menu-type-outside .cell-outside .logo {
  margin-top: 36px;
  width: 81px;
  transform: translateY(0);
}
.item-outside {
  font-size: 21px;
}
.item-outside.button-menu-top {
  height: 120px;
}
.item-outside.button-menu-bottom {
  height: 220px;
}
.item-outside.button-menu-center {
  cursor: pointer;
  height: calc(100vh - 120px - 220px);
}
.menu-type-outside .cell-outside .button-menu-center a {
  margin-top: 95px;
}
.menu-type-outside .cell-outside .button-menu-center:hover a {
  color: #ffdb00;
}
.menu-type-outside .cell-outside .button-menu-center, .button-menu-bottom {
  display: flex;
  align-items: center;
  font-family: 'Roboto-Regular';
}
.menu-type-outside .cell-outside .button-menu-bottom a {
  background: #00751a;
  width: 160px;
  padding: 10px 8px;
  border-radius: 5px;
  position: relative;
  top: -6px;
}
.menu-type-outside .cell-outside .button-menu-bottom a:hover {
  background: #ffdb00;
  transition: all 0s linear;
  color: #444;
}
.menu-type-outside .cell-outside .button-menu-center a, .button-menu-bottom a {
  transform: rotate(-90deg);
}

.cell-outside.hide-1 {
  width: 0;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0s linear;
}
.cell-outside.hide-1 .logo {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: all 0s linear;
}
.cell-outside.hide-1 .button-menu-bottom a {
  transition-delay: 0s;
  pointer-events: none;
  visibility: hidden;
  -webkit-transition: all 0s linear;
  transition: all 0s linear;
}
/**************************************/
/*           CELL INSIDE              */
/**************************************/
.menu-type-outside .cell-inside {
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  /* overflow: hidden; */
  flex-wrap: wrap;
  transition: all 0s linear;
  user-select: none;
}
.menu-type-outside .cell-inside.expand {
  visibility: visible;
  opacity: 1;
  pointer-events: unset;
  display: flex;
  transition: all 0s linear;
}
.menu-type-outside .cell-inside-1, .cell-inside-2 {
  background: #00ac2b;
  position: relative;
  width: 100%;
}
.menu-type-outside .cell-inside-2 {
  background: #00751a;
  height: calc(59vh - 58px);
}
.menu-type-outside .cell-inside-1 {
  margin-bottom: 58px;
  height: 41vh;
  border-bottom: 1px solid #00ac2b;
}

.menu-type-outside .cell-inside-1:after {
  content: "";
  width: calc(100% - 24px);
  height: 0;
  position: absolute;
  bottom: -30px;
  left: 0;
  background: transparent;
  border-top: 30px solid #00ac2b;
  border-right: 24px solid transparent;
  transition: all 0s linear;
}
.menu-type-outside .cell-inside-2:after {
  content: "";
  width: calc(100% - 24px);
  height: 0;
  position: absolute;
  top: -29px;
  left: 0;
  background: transparent;
  border-bottom: 30px solid #00751a;
  border-right: 24px solid transparent;
  transition: all 0s linear;
}

.menu-type-outside .cell-inside .item-logo {
  text-align: center;
  position: relative;
}
.menu-type-outside .cell-inside .item-logo::before {
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  height: 1px;
  background-color: #fff;

}
.menu-type-outside .cell-inside .item-logo .custom-logo-link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 115px;
}
.menu-type-outside .cell-inside .item-logo img {
  width: 235px;
}
.menu-type-outside .cell-inside .item-footer-bottom {
  height: 500px;
}
.btn-menu {
  position: absolute;
  bottom: -58px;
  width: 100%;
  height: 58px;
  z-index: 2;
}

/* .menu-type .waring-to-service{
  display: none;
}
.menu-type.open .waring-to-service{
  right: 42px;
  top: 136px;
  display: block;
  position: absolute;
  z-index: 5;
  width: 20px;
  text-align: center;
}
.menu-type.open .waring-to-service .icon-waring-to-service{
  height: 21px;
  cursor: pointer;
  filter: invert(94%) sepia(98%) saturate(6326%) hue-rotate(149deg) brightness(90%) contrast(101%);
}
.menu-type.open .waring-to-service .wts-container{
  position: relative;
}
.menu-type.open .waring-to-service .popup-waring-to-service{
  position: absolute;
  bottom: -12px;
  left: 25px;
  border-radius: 10px;
  background-color: white;
  width: 245px;
  color: #2c2c2d;
  text-align: center;
  padding: 13px 1px;
  font-size: 16.5px;
  font-family: 'Roboto-Regular';
  line-height: 1.3;
  display: none;
}
.menu-type.open .waring-to-service .popup-waring-to-service.show-info{
  display: block;
}
.menu-type.open .waring-to-service .popup-waring-to-service:after{
  content: "";
  position: absolute;
  display: block;
  bottom: 23px;
  left: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid white;
} */

/**************************************/
/*           MENU LIST               */
/*************************************/
.list-item-top {
  margin-top: 20px;
  position: relative;
  z-index: 2;
}
.list-item li {
  text-align: left;
  margin-bottom: 15px;
}
.left-menu-main__info{
  display: flex;
}
.list-item li:last-of-type {
  margin-bottom: 0;
}
.list-item li a {
  padding-left: 45px;
  font-family: 'Roboto-Regular';
  font-size: 22px;
  /* width: 100%; */
  display: block;
  line-height: 27px;
}
.list-item .left-menu-main__info a{
  margin-right: 12px;
}
.left-menu-i{
  position: relative;
}
.left-menu-i span{
  cursor: pointer;
  width: 6.25px;
  height: 18px;
}
.left-menu-i img{
  width: 6.25px;
  height: 18px;
}
.left-menu-info{
  position: absolute;
  background: white;
  padding: 10px;
  border-radius: 10px;
  width: 240px;
  top: 5px;
  left: 28px;
  transform: translate(0%,-50%);
  font-size: 15px;
  font-family: 'Roboto-Light';
}
.left-menu-info::after{
  content: "";
  position: absolute;
  top: calc(50% - 6px);
  left: -10px;
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 11px solid white;
}
.top-navigation .list-item li:nth-child(1) > span.show-info::after{
  display: block
}
@media (max-width: 1366px ) and (max-height: 620px) {
  .list-item-top {
    margin-top: 10px;
    position: relative;
    z-index: 2;
  }
  .list-item li {
    margin-bottom: 12px;
  }
 .list-item li a {
   font-size: 17px;
 }
}
@media (min-height: 700px) {
  .list-item li {
    margin-bottom: 22px;
  }
}
nav#secondary-navigation {
  position: absolute;
  top: 5px;
  width: 100%;
}
.cell-inside-2 .item-menu {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  position: relative;
  top: -10px;
  z-index: 4;
}
.cell-inside-2 .item-menu .list-item-bottom {
  width: 100%;
}
.list-button-app {
  margin-top: 5px;
  width: 100%;
  padding: 0 35px;
  box-sizing: border-box;
  position: absolute;
  bottom: 5px;
}
.list-menu .btn-launch {
  color: #fff;
  background: var(--color-green);
  width: 100%;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  transition: all 0s linear;
  margin-bottom: 15px;
}
.list-menu .btn-launch:hover {
  background: #ffdb00;
  transition: all 0s linear;
}
.list-menu .btn-launch:hover a {
  color: #444;
}
.list-menu .btn-launch a {
  display: block;
  padding: 10px 30px;
  font-family: 'Roboto-Regular';
}
.btn-list-social {
  width: 100%;
  height: 100%;
  position: relative;
}
.btn-social {
  margin-bottom: 15px;
}
.btn-social .btn-social-1 {
  width: 62px;
  height: 62px;
  box-sizing: border-box;
  background: var(--color-green);
  border-radius: 4px;
  transition: all 0s linear;
}
.btn-social .btn-social-1:hover {
  background: #ffdb00;
  transition: all 0s linear;
}
.btn-social .btn-social-1 a, .btn-social .btn-social-1 span {
  width: 100%;
  height: 100%;
  align-items: center;
}
.btn-social .btn-social-1.btn-term {
  background: transparent;
  border: 3px solid var(--color-green);
}
.btn-social .btn-social-1.btn-term:hover {
  border-color: #ffdb00;
}
.btn-social .btn-social-1.btn-term:hover img{
  filter: invert(31%) sepia(83%) saturate(3650%) hue-rotate(360deg) brightness(104%) contrast(104%);;
}
.btn-social .btn-social-1 .ch-play img {
  width: 37px;
  height: 40px;
}
.btn-social .btn-social-1 .app-store img {
  width: 30px;
  height: 37px;
}

.menu-secondary-menu-container .left-menu-i img{
  filter:invert(45%) sepia(99%) saturate(2900%) hue-rotate(110deg) brightness(93%) contrast(103%)
}
/**************************************/
/*           BOTTOM MENU             */
/************************************/
.bottom-menu {
  height: 120px;
  width: 100%;
  transform: translateY(90px);
  transition: all 0.1s ease-in-out;
  position: relative;
  z-index: 99;
}
.bottom-menu.expand-1 {
  transform: translateY(0px);
  transition: all 0.1s ease-in-out;
}
.bottom-menu.expand-1 .bottom-menu-content .bottom-menu-left.mini-triangle::after {
  content: unset;
}
.bottom-menu-content {
  position: relative;
  height: 100%;
}
.bottom-menu-content .bottom-menu-side {
  width: 51%;
  height: 100%;
  position: relative;
  cursor: pointer;
}
.bottom-menu.expand-1 .bottom-menu-side.bottom-menu-right {
  width: 50%;
}
.bottom-menu-content .bottom-menu-left {
  background: #cbd7dd;
}
.bottom-menu-content .bottom-menu-right {
  background: #8d9da3;
  z-index: 3;
  cursor: pointer;
}
.bottom-menu-content .bottom-menu-right::before {
  content:"";
  width: 0;
  height: 0;
  border-bottom: 120px solid #8d9da3;
  border-left: 92px solid transparent;
  position: absolute;
  z-index: 0;
  left: -92px;
}
.bottom-menu-content .bottom-menu-left.mini-triangle::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 26px solid transparent;
  border-right: 26px solid transparent;
  border-bottom: 32px solid #cbd7dd;
  position: absolute;
  top: -31px;
  right: 0px;
  transform: translate(0);
  opacity: 1;
  background-color: transparent !important;
  cursor: pointer;
}
.bottom-menu-content .bottom-menu-left.mini-triangle.bounce::after {
  animation: bounce 2s infinite ease-in-out;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}


.bottom-menu-list {
  align-items: center;
  padding: 0 28px;
  height: 100%;
  position: relative;
}
.bottom-menu-list .bottom-menu-item-1 {
  width: 120px;
  text-align: center;
  font-size: 16px;
  color: #2c753d;
  opacity: 0;
  transform: translateY(40px);
  transition: all 0.1s ease-in-out;
}
#th-site .bottom-menu-list .bottom-menu-item-1 {
  font-size: 18px;
}
.bottom-menu-list .bottom-menu-item-1 .footer-item {
  width: 60px;
  height: 60px;
  margin: auto;
  position: relative;
}
.bottom-menu-list .bottom-menu-item-1 img {
  width: 100%;
  top: 0;
  transition: all 0.2s;
}
.bottom-menu-list .bottom-menu-item-1:hover img {
  transform: scale(1.2);
  transition: all 0.2s;
}
.bottom-menu-list .bottom-menu-item-1 a {
  color: unset;
  display: block;
  user-select: none;
}
.bottom-menu.expand-1 .bottom-menu-list .bottom-menu-item-1 {
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  position: relative;
  z-index: 3;
}
.bottom-menu-list.resize .bottom-menu-item-1 {
  font-size: 16.5px;
  transition: all 0.1s ease-in-out;
}
#th-site .bottom-menu-list.resize .bottom-menu-item-1 {
  font-size: 18px;
}
.bottom-menu-list.resize {
  transition: all 0.1s ease-in-out;
}

.bottom-menu-list-2 {
  padding: 0 0 0 18px;
  align-items: center;
  height: 100%;
  transform: translateY(0);
  color: #e8e7e7
}

.bottom-menu-list-2 .bottom-menu-item-2 {
  width:207px;
  text-align: center;
  font-size: 16.5px;
  opacity: 0;
  transition: all 0.1s ease-in-out;
}
.l-container.push .bottom-menu.expand-1 .bottom-menu-list-2 .bottom-menu-item-2:nth-child(1) {
  /* margin-right: 38px; */
  /* margin-left: 50px; */
  margin-left: 28px;
  margin-right: -5px;
  
}
.l-container .bottom-menu.expand-1 .bottom-menu-list-2 .bottom-menu-item-2:nth-child(1) {
  margin-left: 64px;
  margin-right: 11px;
  transition: unset;
}
.bottom-menu-item-2 .img-bg-count{
  width: 163px;
}
.bottom-menu-list-2 .bottom-menu-item-2 .img-data, .sticky-menu-list-2 .sticky-menu-item-2 .img-data {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}
.bottom-menu-list-2 .bottom-menu-item-2 .img-data p, .sticky-menu-list-2 .sticky-menu-item-2 .img-data p {
  position: absolute;
  /* top: 38px; */
  font-size: 15px;
  font-family: 'Roboto-Regular';
  color: #e8e7e7;
  user-select: none;
}
.bottom-menu-list-2 .bottom-menu-item-2 .img-data span, .sticky-menu-list-2 .sticky-menu-item-2 .img-data span {
  font-family: 'Roboto-Bold';
  font-size: 14px;
}
.bottom-menu-list-2 .bottom-menu-item-2 svg {
  width: 207px;
  user-select: none;
}
.bottom-menu-list-2 .bottom-menu-item-2 svg text{
  fill: #e8e7e7;
  font-family: 'Roboto-Regular';
  font-size: 27px;
  letter-spacing: 0.7px;
}
.bottom-menu-list-2.resize .bottom-menu-item-2 {
  font-size: 16.5px;
  transition: all 0.1s ease-in-out;
}
.bottom-menu-list-2.resize {
  padding-left: 20px;
  transition: all 0.1s ease-in-out;
}

.bottom-menu.expand-1 .bottom-menu-list-2 .bottom-menu-item-2 {
  position: relative;
  left: -38px;
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.1s ease-in-out;
  cursor: default;
}
.bottom-menu.expand-1 .bottom-menu-list-2 .bottom-menu-item-2 .data-text {
  font-family: 'Roboto-Medium';
  font-size: 16px;
}
.bottom-menu {
  bottom: 0px;
  position: fixed;
}
/* style for language*/
.language-and-location{
  display: flex;
  font-family: 'Roboto-Regular';
  font-size: 18px;
}
.language-and-location .location{
    margin-right: 5px;
}
.language-and-location .language a{
    text-decoration: none;
    display: flex;
    align-items: center;
}
.language-and-location .language .lang-icon{
  display: flex;
  margin-left: -3px;
}
.language-and-location-dropdown-country {
    position: relative;
  }
  .language-and-location-dropdown-country .menu-local-menu-container {
    display: none; /*hide original SELECT element:*/
  }
  .select-selected {
    border: unset !important;
    color: var(--color-green-d);
    padding-right: 40px;
    cursor: pointer;
    user-select: none;
  }
  /*style the arrow inside the select element:*/
  .select-selected:after {
    position: absolute;
    content: "";
    top: 8px;
    right: 22px;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-color: var(--color-green-d) transparent transparent transparent;
  }
  .select-selected::before {
    display: none;
  }
  /*point the arrow upwards when the select box is open (active):*/
  .select-selected.select-arrow-active:after {
    border-color: transparent transparent var(--color-green-d) transparent;
    top: 1px;
  }
  .select-selected.select-arrow-active::before{
    content: "";
    position: absolute;
    display: block;
    top: 17px;
    right: 19px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
  }
  /*style the items (options), including the selected item:*/
  .select-items a{
    padding: 10px 20px 10px 20px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
    background-color: white;
    letter-spacing: 0.3px;
    color: #343435;
    display: block;
  }
  /*style items (options):*/
  .select-items {
    width: 160px;
    position: absolute;
    top: 27px;
    left: -21px;
    right: 0;
    z-index: 99;
    font-weight: lighter;
  }
  .select-items a:first-of-type, .cat-select-items a:first-of-type {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  .select-items a:last-of-type, .cat-select-items a:last-of-type {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  /*hide the items when the select box is closed:*/
  .select-hide {
    display: none;
  }
  .select-items a:hover, .select-items .same-as-selected {
    background-color: var(--color-green-d);
    color: #fff;
  }
  .select-items .same-as-selected {
    background-color: var(--color-green-d);
    color: #fff;
    pointer-events: none;
  }
/* top main header*/
.top-main-header{
  background-color: rgba(204, 216, 222, 0.85);
  padding: 35px 53px 22px 90px;
  z-index: 999;
}
.top-main-header .header{
  display: flex;
  margin-bottom: 10px;
}
.top-main-header .header .title{
  font-family: 'BebasNeue-Bold';
  font-size: 50px;
  flex: 1;
  color: var(--color-green-d);
  margin: 0;
}
.top-main-header .header-content{
  font-family: 'Roboto-Regular';
  font-size: 22px;
  line-height: 1.4;
  color: #343435;
}
ul#menu-language-menu > li > a {
  display: none;
}
ul#menu-language-menu ul.sub-menu li {
  display: inline-block;
}
#local-navigation {
  margin-top: 5px;
}
.list-item li.current-page-ancestor a, .list-item li.current_page_item a, .list-item li a:hover {
  color: var(--color-yellow);
}
ul#menu-intra-city-menu.list-item li a , ul#menu-long-haul-menu.list-item li a {
  color: #fff;
}
ul#menu-intra-city-menu.list-item li a:hover, ul#menu-long-haul-menu.list-item li a {
  color: var(--color-yellow);
}
/*BOTTOM MENU*/
.l-container.push .bottom-menu.expand-1 .bottom-menu-content .bottom-menu-left {
  width: calc(100% - 53% - 53px);
}
.l-container.push .bottom-menu-content .bottom-menu-left {
  width: 47%;
}
.l-container.push .bottom-menu-content .bottom-menu-right {
  width: 53%;
}
.l-container.push .bottom-menu.expand-1 .bottom-menu-side.bottom-menu-right {
  width: 53%;
}
.bottom-menu.expand-1 .bottom-menu-content .bottom-menu-left {
  width: calc(100% - 50% - 53px);
  padding: 0 48px;
  box-sizing: border-box;
  cursor: pointer;
}

.bottom-menu.expand-1 .bottom-menu-content .bottom-menu-left::before {
  content: "";
  width: 0;
  height: 0;
  border-bottom: 56px solid #cbd7dd;
  border-right: 52px solid transparent;
  position: absolute;
  z-index: 2;
  right: -52px;
}
.bottom-menu.expand-1 .bottom-menu-content .bottom-menu-list::after {
  content: "";
  width: 43px;
  height: 66px;
  position: absolute;
  right: -90px;
  top: 54px;
  z-index: 2;
  background: #cbd7dd;
}
.bottom-menu.expand-1 .bottom-info-meter{
  display: flex;
}
.bottom-info-meter{
  position: absolute;
  top: 10px;
  right: 111px;
  display: none;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  cursor: pointer;
}
.l-container.push .bottom-info-meter{
  right: 254px;
}
.bottom-info-meter-container{
  position: relative;
}
.bottom-info-meter-container .icon-i-info-meter{
  height: 18px;
}
.bottom-info-meter-container .popout-info-meter{
  position: absolute;
  bottom: 38px;
  right: -21px;
  border-radius: 10px;
  background-color: white;
  color: #2c2c2d;
  padding: 11px 16px;
  font-size: 15px;
  font-family: 'Roboto-Light';
  line-height: 1.3;
  white-space: nowrap;
  visibility: hidden
}
.bottom-info-meter-container .popout-info-meter.show-info{
  visibility: visible;
}
.bottom-info-meter-container .popout-info-meter:after{
  content: "";
  position: absolute;
  display: block;
  bottom: -10px;
  right: 16px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid white;
}

ul#menu-language-menu ul.sub-menu li:hover, ul#menu-language-menu ul.sub-menu li.current-menu-item {
  opacity: 1;
}
ul#menu-language-menu ul.sub-menu li {
  opacity: 0.5;
  width: 30px;
}
.sticky-menu-list .sticky-menu-item-1 a:hover {
  color: var(--color-green);
}
.sticky-menu-list .sticky-menu-item-1 a {
  color: unset;
}
.top-navigation {
  display: none;
}
.top-navigation.active {
  display: block;
}
#fc_frame{
  display: none;
}

.bottom-menu.menu-right-bottom {
  right: 0;
  width: auto;
  height: 90px;
}
.bottom-menu.menu-right-bottom .bottom-menu-list {
  padding: 0 10px;
}
.bottom-menu.menu-right-bottom .bottom-menu-content .bottom-menu-left {
  width: auto;
  border-radius: 9px 0 0 0;
  padding: 0;
  cursor: default;
  transition: all 0.3s;
  bottom: 0;
}
.bottom-menu.menu-right-bottom .bottom-menu-content .bottom-menu-left::before,
.bottom-menu.menu-right-bottom .bottom-menu-content .bottom-menu-list::after {
  display: none;
}
@media (max-width: 1367px) {
  .menu.menu-type {
    height: auto;
    bottom: 0;
  }
  .menu-type-outside .cell-inside-1 {
    height: calc(41vh/var(--scale-standard));
  }
  .menu-type-outside .cell-inside-2 {
    height: calc(59vh/var(--scale-standard) - 58px);
  }
  .menu-type-outside .cell-inside {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 273px;
  }
  .menu-trigger .menu-content .triangle {
    position: absolute;
    left: -93px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .l-container .omnipage{
    background-position: right bottom 135px;
  }
}
.control-arrow-bottom-menu{
  display: none;
}

.l-container #menuBottom {
  transition: all 0.3s ease-in-out !important;
}
.l-container #menuBottom.turnoff-bottom {
  transform: translateX(calc( 100% - 55px));
  transition: all 0.3s ease-in-out !important;
}

.l-container #menuBottom.turnoff-bottom .bottom-menu-item-1:not(.control-arrow-bottom-menu){
  margin-left: 17px;
}

.menu-right-bottom .bottom-menu-item-1.control-arrow-bottom-menu{
  width: 0px;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 26px solid #ffdb00;
  margin-left: 3px;
  transition: all 0.3s ease-in-out !important;
}
.menu-right-bottom .bottom-menu-item-1.control-arrow-bottom-menu:hover{
  transform: scale(1.2);
}

.l-container #menuBottom.turnoff-bottom .bottom-menu-left .control-arrow-bottom-menu{
  transform: rotate(180deg);
}
.bottom-menu-close{
  display: none;
  color: #00751a;
  font-size: 36px;
  font-weight: bold;
  position: absolute;
  top: 4px;
  right: 0;
  z-index: 5;
  cursor: pointer;
  padding: 3px 7px 15px 15px;
  line-height: 20px;
  user-select: none;
}
.bottom-menu-close img {
  width: 15px;
}
.bottom-menu-close:hover{
  color: #00ac2b;
}
.modal-popup {
  display: none;
  position: fixed;
  z-index: 9999;
  padding-top: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(35, 35, 35, 0.8);
}
.modal-popup-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border-radius: 8px;
  width: 35%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  font-family: 'Roboto-Regular', sans-serif !important;
  color: #212529;
}
.modal-popup-content ul {
  margin: 0 0 0 10px;
  padding: inherit;
}
.modal-popup-content li{
  list-style-type: inherit;
}
.modal-popup-content.larger {
  width: 60%;
}
.modal-popup-content.medium {
  width: 50%;
}
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}
@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}
.close {
  color: white;
  float: right;
  font-size: 36px;
  font-weight: bold;
  line-height: 24px;
  padding-right: 7px;
  padding-top: 2px;
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.modal-popup-header {
  padding: 10px 16px;
  background-color: #00ac2b;
  color: white;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-top: -1px;
}
.modal-popup-header div:first-child {
  border-bottom: 1px solid #fff;
}
.modal-popup-header div:first-child:last-child {
  border-bottom: none;
}
.modal-popup-body {padding: 8px 16px;}
.modal-popup-footer {
  padding: 2px 16px;
  background-color: #00ac2b;
  color: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.modal-popup-header h1, .modal-popup-header p {
  font-size: 22px;
  font-family: 'Roboto-Regular', sans-serif;
  font-weight: 400;
  margin: 10px 0;
}
#myBtn {
  cursor: pointer;
  display: contents;
}
button.btn-custom {
  background-color: #4285f4!important;
  color: #fff;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  padding: .84rem 2.14rem;
  font-size: .81rem;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  -o-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  margin: .375rem;
  border: 0;
  -webkit-border-radius: .125rem;
  border-radius: .125rem;
  cursor: pointer;
  text-transform: uppercase;
  white-space: normal;
  word-wrap: break-word;
  color: #fff;
}
button.btn-custom:hover {
  -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
  box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
  outline: 0;
}
.modal-popup-body h2, .modal-popup-body p {
  font-size: 16px;
  font-family: 'Roboto-Regular', sans-serif;
  font-weight: 400;
  color: #212529;
  margin-bottom: 8px;
}
.modal-popup-body h2 {
  padding-bottom: 8px;
  border-bottom: 1px solid #00ac2b;
  margin-top: 0;
}
.modal-popup-footer {
  text-align: right;
}
div#map {
  width: 100%;
}


/*
 * Blog Common
 */

.blog #header-post .container {
  display: flex;
  align-items: center;
  padding: 25px 0;
  position: relative;
  width: 905px;
  margin: 0 auto;
}
.blog #header-post .container .form-box img {
  width: 38px;
}
.blog .language-and-location .location {
  margin-right: 20px;
}
.blog .social-icon-content {
  margin-left: -5px;
}
.blog .form-box {
  max-width: 76%;
  -ms-flex-preferred-size: 76%;
  flex-basis: 76%;
}
.blog .language-and-location {
  max-width: 24%;
  -ms-flex-preferred-size: 24%;
  flex-basis: 24%;
  margin-top: 10px;
}
.blog .form-box input.search-field {
  height: 35px;
  width: 95%;
  border: 2px solid #8E9EA4;
  border-radius: 5px;
  padding-left: 15px;
}
.blog .form-box button.search-submit {
  margin-left: -45px;
  height: 34px;
  padding: 0 12px;
  border: none;
  background: #8E9EA4;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff;
  cursor: pointer;
}
.blog .form-box i.fas.fa-search {
  transform: rotate(90deg);
}
.blog .social-icon-content a {
  margin-right: 20px;
}
p.qtranxs-available-languages-message {
  display: none;
}
.text-no-wrap{
  white-space: nowrap;
}

#ph-site .swiper-container.driver-home .iconboxmodal, #id-site .swiper-container.driver-home .iconboxmodal, #id-site-en .swiper-container.driver-home .iconboxmodal {
  padding-right: 0;
}
.driver-home .iconboxmodal {
  z-index: 99;
  top: 155px !important;
  right: 40px !important;
  width: 9.8px !important;
  position: absolute;
}
#th-site .driver-home .iconboxmodal, #th-site-en .driver-home .iconboxmodal {
  width: 8px !important;
}
.content-footer-driver {
  background-color: #565656;
  padding: 8px 26px;
}
.content-footer-driver .logo-driver-bottom {
  position: unset !important;
  width: 51px !important;
  display: block;
}
.content-footer-driver .logo-driver-bottom #myBtn {
  width: 100%;
}
.iconboxmodal #myBtn {
  display: block !important;
  height: 18px;
}
.icon-open img {
  height: 18px;
  margin-left: 10px;
}
#th-site .icon-open img, #th-site-en .icon-open img {
  height: 16px;
  margin-left: 10px;
}
#vn-site .coming-soon-vn, #vn-site-en .coming-soon-vn{
  display: block;
}
.coming-soon-vn{
  background-image: url(/wp-content/uploads/2019/05/pilot-vn-coming-soon.png);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 12%;
  left: 39%;
  font-size: 27px;
  font-weight: bold;
  color: var(--color-green);
  padding: 6px 158px 40px 69px;
  display: none;
}
.business-program-city .bg-title>div.clearflex {
  flex-direction: row !important;
}
.business-program-city .bg-title>span {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
}
.modal-popup-body a {
  color: #999999;
}
.c-page__Require .iconboxmodal {
  border-bottom: 1px solid #b9b9b9;
  height: 70px;
  display: flex;
  align-items: center;
}
.c-page__Require .iconboxmodal p {
  margin-left: 50px;
  color: #3fae2a;
  font-size: 20px;
  font-weight: bold;
}
#th-site .c-page__Require .iconboxmodal p {
  font-weight: 500;
}
@media (max-width: 767px) {
  .c-page__Require .iconboxmodal {
    height: 50px;
  }
}
span.red {
  background: red;
   border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em; 
}

span.grey {
  background: #cccccc;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em; 
}
span.green {
  background: #5EA226;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em; 
}
span.blue {
  background: #5178D0;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em; 
}
span.pink {
  background: #EF0BD8;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.6em; 
}
span.icon-open {
  cursor: pointer;
}

/* loading icon deliveree*/
.Loanding-Deliveree{
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 100011;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.Loanding-Deliveree .Locating-Drivers{
  margin: auto;
}
.Loanding-Deliveree .Loader-Icon{
  background: rgb(63, 174, 41);
  width: 116px;
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  position: relative;
}
.Loanding-Deliveree .Logo{
  display: flex;
  align-items: center;
  position: relative;
  height: inherit;
  padding: 0px 20px;
}
.Loanding-Deliveree .Logo img{
  width: auto;
  height: 68px;
}
@keyframes rotating {
  0%{-ms-transform:rotate(0deg);-webkit-transform:rotate(0deg);transform:rotate(0deg)}
  to{-ms-transform:rotate(1turn);-webkit-transform:rotate(1turn);transform:rotate(1turn)}
}
.Loanding-Deliveree .Line-Animation{
  width: 94px;
  height: 94px;
  border: 1px dashed rgb(255, 255, 255);
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 100px;
  animation: rotating 6s linear infinite;
}
.blog-post-button-seo.btn-edit a span {
  margin-right: 13px;
}
.language-somepage {
  position: absolute;
  top: 0;
  right: 20px;
  background-color: #e6e6e6;
  padding: 9px 14px 6px;
  display: flex;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: 999;
}
.language-somepage .menu-language-menu-container .sub-menu{
  font-size: 16.5px;
  width: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.language-close {
  color: #00751a;
  font-size: 32px;
  margin-left: 10px;
  cursor: pointer;
  margin-top: -6px;
  font-weight: 600;
}

#th-site .language-close {
  font-size: 36px;
  font-weight: bold;
  display: none;
}
/* .language-somepage ul#menu-language-menu ul.sub-menu li:first-child {
  margin-right: 9px;
} */
.extra-service .service-panel p.content_remark {
  margin-top: 5px;
  font-size: 11px;
}
#th-site .extra-service .service-panel p.content_remark {
  margin-top: 5px;
  font-size: 14px;
}
.open-livechat-custom-container.show{
  visibility: visible;
}
.open-livechat-custom-container {
  visibility: hidden;
  position: fixed;
  z-index: 1;
  top: 100px;
  left: 20px;
  text-align: right;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.26);
  background-color: var(--green-mobile);
  border-radius: 7px;
}
.open-livechat-custom-box {
  position: relative;
  display: flex;
  flex-flow: wrap column;
  padding: 15px;
}
.open-livechat-custom-box a {
  border: 1px solid #fff;
  color:white;
  border-radius: 3px;
  padding: 8px 15px;
  text-align: center;
  font-family: 'Roboto-Regular';
  font-size: 16px;
}
#th-site .open-livechat-custom-box a{
  font-family: 'DB Adman X';
  font-size: 21px;
  font-weight: 500
}
.open-livechat-custom-box:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  bottom: -20px;
  left: calc(50% - 15px);
  box-sizing: border-box;
  border: 10px solid black;
  border-color: transparent transparent var(--green-mobile) var(--green-mobile);
  transform-origin: 0 0;
  transform: rotate(-45deg);
  /* box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, .26) */
}
.menu-chat-driver .open-livechat-custom-box:after {
  right: 0;
  bottom: -15px;
  left: unset;
  transform: unset;
  border-color: var(--green-mobile) var(--green-mobile) transparent transparent;
}
.open-livechat-custom-box .livechat-customer {
  margin-bottom: 10px;
}
.open-livechat-custom-box a:hover {
  background-color: #00751a;
}
.open-livechat-custom-container img {
  width: 45px;
  margin-top: 10px;
}
.livechat-driver-container {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,.5);
  font-size: 16px;
  font-family: "Roboto-Regular";
  z-index: 2147483601;
}
#th-site .livechat-driver-container{
  font-size: 26px;
  font-family: "DB Heavent";
  font-weight: 400;
}
.livechat-driver-box {
  width: 425px;
  min-height: 162px;
  background-color: var(--green-mobile);
  color: #fff;
  border-radius: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom -15px left 5px;
}
.livechat-driver-container .box-right {
  padding: 17px 8px 17px 127px;
  text-align: center;
  line-height: 1.6;
}
#th-site .livechat-driver-container .box-right{
  line-height: 1.1;
}
.livechat-driver-container a{
  color: var(--color-hyperlink-green);
  text-decoration: underline;
}
.livechat-driver-container #count-down {
  margin: 5px 0;
  font-size: 24px;
}
#th-site .livechat-driver-container #count-down{
  font-size: 35px;
}
.livechat-driver-container .close-countdown-popup {
  background-color: #9b9b9b;
  color: #fff;
  width: 425px;
  margin-top: 10px;
  padding: 7px 0;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  font-size: 17px
}
#th-site .livechat-driver-container .close-countdown-popup{
  font-size: 26px;
  padding: 4px 0;
}
.livechat-driver-container .close-countdown-popup:hover {
  background-color: var(--main-text-color);
}
.button-freshchat{
  border-radius: 34px 8px 34px 34px;
  box-shadow: 0 5px 4px 0 rgba(0,0,0,.26);
  cursor: pointer;
  height: 60px;
  padding: 5px 10px 10px;
  width: 60px;
  background-color: #198d17 !important;
  color: #ffffff !important;
  border-color: transparent #198d17 transparent transparent;
  background: 0 0;
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  vertical-align: baseline;
  box-sizing: border-box;
  bottom: 19px;
  position: fixed;
  right: 15px;
  z-index: 2147483600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-freshchat-content{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 2px 0 0 2px;
  text-align: center;
  vertical-align: middle;
}
.button-freshchat-content img{
  width: 25px;
}

/* Popup-Web  */
.Popup-Web{
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,.5);
  font-family: "Roboto-Regular";
  z-index: 2147483601;
}
#th-site .Popup-Web{
  font-family: "DB Heavent";
}
.Popup-Web .Popup-Container{
  top: inherit;
  bottom: inherit;
  align-self: center;
  margin: auto;
  width: 100%;
  max-width: 310px;
  font-size: 19px
}
#th-site .Popup-Web .Popup-Container, #th-site .Popup-Web .Popup-Title{
  font-size: 28px;
  font-weight: 400;
}
.Popup-Web .Popup-Main{
  background: var(--green-mobile);
  color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.Popup-Web .Popup-Icon{
  background: white;
  height: 64px;
  border-radius: 64px;
  justify-content: center;
  align-items: center;
  left: 50%;
  margin: -32px 0 0 -32px;
  width: 64px;
  position: absolute;
  box-shadow: 1px 1px 25px #0f370f;
  display: flex;
  flex-direction: column;
}
.Popup-Web .Popup-Icon img {
  width: auto;
  height: 40px;
}
.Popup-Web .Popup-Center {
  margin-top: 47px;
  padding: 0 15px 15px;
  box-sizing: border-box;
  width: 100%;
  flex: 1;
}
.Popup-Web .Popup-Normal{
  width: 100%;
  box-sizing: border-box;
}
.Popup-Web .Popup-Title{
  color: var(--color-yellow);
  margin: 0 0 12px;
  font-size: 19px;
  text-align: center;
  font-weight: 400;
}
.Popup-Web .Popup-Content{
  padding: 0;
  text-align: center;
  line-height: 24px;
  margin: 0;
}
.Popup-Web .Popup-Action{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px -5px 0;
}
.Popup-Web .Popup-Action a{
  text-transform: capitalize;
  font-weight: 400;
  flex: 1;
  margin: 0 5px;
  line-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  outline: 0;
  width: 100%;
  border: 0 none;
  cursor: pointer;
}
.Popup-Web .Popup-Action .Cancel{
  color: #4b4b4b;
  background-color: #cecece;
}
.Popup-Web .Popup-Action .Confirm{
  color: var(--green-mobile);
  background-color: white;
}
.Popup-Web .Popup-Action .Confirm:hover{
  color: white;
  background-color: var(--color-yellow);
}

/*  */
.driver-hotline-popup .Confirm img{
  margin-right: 9px;
  width: 22px;
}

.blog-inapp .hidden-inapp,
.blog-inapp #menu-main-media-footer-mobile,
.blog-inapp .logo-chat,
.blog-inapp .img-top-menu,
.blog-inapp .back-box,
.blog-inapp .click-go-black{
  display: none;
}
.blog-inapp .site-branding.logo a{
  pointer-events: none;
}