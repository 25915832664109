@media (min-width: 320px) and (max-width: 767px) {
  html {
    -webkit-tap-highlight-color: transparent;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    scroll-behavior: smooth;
    font-size: 17px;
  }

  body {
    width:100vw;
    height:auto;
    color: #777;
  }
  article,
  aside,
  footer,
  header,
  nav,
  section {
    display: block;
  }

  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }

  figcaption,
  figure,
  main {
    display: block;
  }

  figure {
    margin: 1em 0;
  }

  hr {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  pre {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }

  a:active,
  a:hover {
    outline-width: 0;
  }

  abbr[title] {
    border-bottom: 1px #767676 dotted;
    text-decoration: none;
  }

  b,
  strong {
    font-weight: inherit;
  }

  b,
  strong {
    font-weight: 700;
  }

  code,
  kbd,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  dfn {
    font-style: italic;
  }

  mark {
    background-color: #eee;
    color: #222;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  audio,
  video {
    display: inline-block;
  }

  audio:not([controls]) {
    display: none;
    height: 0;
  }

  img {
    border-style: none;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  button,
  input {
    overflow: visible;
    outline: unset;
  }

  input:focus::-webkit-input-placeholder {
    color: transparent !important;
  }
  input:focus:-moz-placeholder {
    color: transparent !important;
  }
  input:focus::-moz-placeholder {
    color: transparent !important;
  }
  input:focus:-ms-input-placeholder {
    color: transparent !important;
  }

  textarea:focus::-webkit-input-placeholder {
    color: transparent !important;
  }
  textarea:focus:-moz-placeholder {
    color: transparent !important;
  }
  textarea:focus::-moz-placeholder {
    color: transparent !important;
  }
  textarea:focus:-ms-input-placeholder {
    color: transparent !important;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  fieldset {
    border: 1px solid #bbb;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
  }

  legend {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
  }

  progress {
    display: inline-block;
    vertical-align: baseline;
  }

  textarea {
    overflow: auto;
  }

  [type="checkbox"],
  [type="radio"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
  }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  details,
  menu {
    display: block;
  }

  summary {
    display: list-item;
  }

  canvas {
    display: inline-block;
  }

  template {
    display: none;
  }

  [hidden] {
    display: none;
  }
  body#th-site{
    line-height: 1.1;
    font-family: 'DB Adman X';
    font-size: 20px;
  }
  #th-site .txt-head .title {
    font-family: 'DB Heavent';
    font-size: 60px;
    font-weight: bold;
  }
  #th-site .price-calculator-first .title, #th-site .top-main-header .header .title,
  #th-site .bg-introduction .title-introduction{
    font-family: 'DB Heavent';
    font-size: 45px;
    line-height: 1;
  }
  #th-site .overlay .popup .header .title{
    font-family: 'DB Heavent';
    font-size: 35px;
    line-height: 0.8
  }
  #th-site .overlay .popup .header .price{
    font-family: 'DB Heavent';
    font-size: 33px;
    line-height: 0.8
  }
  #th-site .each-country .service-country a,
  #th-site .offices-mb .introduction .title-introduction .title,
  #th-site .omni-title-mb {
    font-family: 'DB Heavent';
    font-size: 40px;
    font-weight: bold;
    line-height: 35px;
  }
  #th-site .modal-popup-header h1, #th-site .modal-popup-header p {
    font-family: 'DB Heavent';
    font-size: 36px;
    line-height: 34px;
    margin: 10px 0;
  }
  #th-site .modal-popup-content {
    font-family: 'DB Adman X' !important;
    font-size: 22px;
    color: #212529;
  }
  #th-site .modal-popup-body h2, #th-site .modal-popup-body p {
    font-size: 22px;
    font-family: 'DB Adman X';
    color: #212529;
    margin-bottom: 8px;
  }
  #th-site .content-menu-item{
    font-family: 'DB Heavent';
    font-size: 30px;
  }
  #th-site .txt-head p, #th-site .offices-mb .content-introduction, #th-site .offices-mb .offices-block .content .title{
    font-family: 'DB Heavent';
    font-size: 22px;
    line-height: 1.1;
  }
  #th-site .main-footer .footer-menu ul li a {
    font-family: 'DB Heavent';
    font-size: 28px;
  }
  #th-site .business-program-city .bg-title, #th-site .title-list-tab .title-tab, #th-site .menu-type-outside .cell-outside .button-menu-center, 
  #th-site .button-menu-bottom, #th-site .top-menu-control{
    font-family: 'DB Heavent';
    font-size: 24px;
  }
  #th-site .lh-business-program-mb .bg-title ,
  #th-site .business-program-mb .bg-title{
    font-family: 'DB Heavent';
    font-size: 24px;
    line-height: 0.9;
  }
  #th-site #service-area .location-checker .search .search-box .search-title,
  #th-site .fare-estimator-form .btn-calculating button,
  #th-site .price-calculator-mb .fare-estimator-form .service-types>.media-n-content .content,
  #th-site .fare-estimator-form .popout .service .content,
  #th-site button.alm-load-more-btn.more.btn-loadmore{
    font-family: 'DB Heavent';
    font-size: 22px;
  }
  #th-site .overlay .popup .footer a, #th-site #th-site  .overlay .popup .footer button,
  #th-site .overlay .popup .footer .back{
    font-family: 'DB Heavent';
    font-size: 22px;
    line-height: 0.8;
    padding: 0px 5px;
    font-weight: 400;
  }
  #th-site .list-menu .btn-launch a, #th-site .bottom-info-meter-container .popout-info-meter,
  #th-site .price-scale .price-start, #th-site .price-end, #th-site .price-road,
  #th-site .vehicle-price .mini-box .row, #th-site .c-select-flpr, #th-site .vehicle-box p,
  #th-site .offices-mb .offices-block .content .address-office, #th-site #service-area .location-checker button,
  #th-site #service-area .location-checker .search .search-box textarea,
  #th-site .omnipage-mb{
    font-family: 'DB Heavent';
    font-size: 20px;
  }
  #th-site .advantages-mb .adv-feature h4{
    font-family: 'DB Adman X';
    font-size: 24px;
    line-height: 1.1
  }
  #th-site .title-footer, #th-site .title-summary, #th-site .title-founder{
    font-family: 'DB Adman X';
    font-size: 24px;
  }
  #th-site .top-main-header .header-content, #th-site .fare-estimator-form,
  #th-site .bg-introduction .content-introduction,
  #th-site .omni-description-mb{
    font-family: 'DB Heavent';
    font-size: 22px;
    line-height: 1;
  }

  #th-site .post-loop .post-items p.title-post, #th-site, #th-site .language-and-location,
  #th-site .bottom-menu-list .bottom-menu-item-1, #th-site .extra-service .service-panel,
  #th-site .business-program-city, #th-site .bottom-menu-list-2 .bottom-menu-item-2 svg text,
  #th-site .content-footer, #th-site .email-submit input, #th-site,
  #th-site #service-area, #th-site #service-area .table-control-map,
  #th-site .fare-estimator-form .address input,
  #th-site .menu-long-haul.thailand-destination-city,
  #th-site .fare-estimator-form .btn-destination .text-destination,
  #th-site .lh-business-program-mb, #th-site .business-program-mb,
  #th-site .price-calculator-mb .fare-estimator-form .address textarea,
  #th-site .price-calculator-mb .overlay .result-popup-long-haul p, #th-site .overlay .popup .popup-text,
  #th-site .advantages-mb .adv-feature p{
    font-family: 'DB Adman X';
    font-size: 20px;
    line-height: 1;
  }
  #th-site .price-calculator-first .content{
    margin-top: unset;
    font-family: 'DB Adman X';
    font-size: 20px;
  }
  #th-site .price-calculator-mb .fare-estimator-form .text-box,
  #th-site .lh-price-calculator-mb .fare-estimator-form .text-box{
    font-family: 'DB Adman X';
    font-size: 20px;
  }
  #th-site .content-summary, #th-site .container-right .block .text, #th-site .bottom-footer, 
  #th-site #service-area .table-map-control .touch-map-mobile{
    font-family: 'DB Adman X';
    font-size: 18px;
  }
  #th-site .top-main-header .header{
    margin-bottom: unset;
  }
  #th-site button, #th-site input, #th-site optgroup, #th-site select, #th-site textarea{
    font-family: 'DB Adman X';
  }
  #th-site #service-area .location-checker, #th-site .lh-price-calculator-mb .fare-estimator-form .service-types>.media-n-content .content,
  #th-site .top-menu-control .sub-menu, #th-site .overlay .popup .booking-info table,
  #th-site .lh-business-program-mb .fb-es-container, #th-site .lh-business-program-mb .time-for-action,
  #th-site .business-program-mb .fb-es-container .bg-content, #th-site .business-program-mb .fb-es-container, #th-site .business-program-mb .tfa-container{
    font-size: 20px;
  }
  #th-site .container-footer-menu>ul>li>a{
    font-family: 'DB Heavent';
    font-size: 22px;
  }
  #th-site .overlay .popup .booking-info table tr td:first-child{
    font-family: 'DB Heavent';
    font-size: 20px;
    font-weight: 500;
  }

  #th-site .overlay .popup .note{
    font-size: 18px;
  }
  #th-site #service-area .map-type-control{
    font-size: 13px;
  }
  img {
    max-width: 100%;
  }
  .site-branding img {
    height: 32px;
    width: auto;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    color: #fff;
    margin: 0;
  }

  p {
    font-weight: 400;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: #fff;
    margin: 0;
    cursor: pointer;
  }

  ul {
    padding: 0;
    margin: 0;
  }
  li {list-style-type: none;}
  a.menu-item {
    text-transform: uppercase;
    margin-bottom: 3px;
    display: block;
  }

  .hr {
    width: 30px;
    height: 3px;
    background: #fff;
    margin: 15px auto 15px auto;
    box-shadow: 1px 1px 2px #222;
  }

  /****************************************/
  /*                Common               */
  /**************************************/
  .disabled{
    pointer-events: none;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .block {
    display: block;
  }
  .text-spacing-minus-2 {
    letter-spacing: -2px;
  }

  /****************************************/
  /*                Margin               */
  /**************************************/
  .mt0 {
    margin-top: 0;
  }
  .mt2 {
    margin-top: 2px;
  }
  .mt5 {
    margin-top: 5px;
  }
  .mt10 {
    margin-top: 10px;
  }
  .mt15 {
    margin-top: 15px;
  }
  .mt20 {
    margin-top: 20px;
  }
  .mt25 {
    margin-top: 25px;
  }
  .mb0 {
    margin-bottom: 0;
  }
  .mb5 {
    margin-bottom: 5px;
  }
  .mb10 {
    margin-bottom: 10px;
  }
  .mb15 {
    margin-bottom: 15px;
  }
  .mb20 {
    margin-bottom: 20px;
  }
  .mb25 {
    margin-bottom: 25px;
  }
  .ml0 {
    margin-left: 0;
  }
  .ml5 {
    margin-left: 5px;
  }
  .ml10 {
    margin-left: 10px;
  }
  .ml15 {
    margin-left: 15px;
  }
  .ml20 {
    margin-left: 20px;
  }
  .ml25 {
    margin-left: 20px;
  }
  .ml25 {
    margin-left: 25px;
  }
  .mr0 {
    margin-right: 0;
  }
  .mr5 {
    margin-right: 5px;
  }
  .mr10 {
    margin-right: 10px;
  }
  .mr15 {
    margin-right: 15px;
  }
  .mr20 {
    margin-right: 20px;
  }
  .mr25 {
    margin-right: 25px;
  }

  /****************************************/
  /*                header               */
  /**************************************/
  .top-main-menu{
    height: 55px;
    background-color: var(--green-mobile);
    display: flex;
  }
  .top-main-menu .logo-chat {
    width: 66px;
    box-sizing: border-box;
  }
  .top-main-menu >div:first-of-type{
    padding: 15px 18px;
    width: auto;
  }
  .top-main-menu .logo-mobile{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .top-main-menu .img-top-menu{
    padding: 19px;
  }
  .top-main-menu .open-live-chat{
    cursor: pointer;
  }
  .top-main-menu .logo-chat:active img{
    filter: invert(12%) sepia(45%) saturate(7486%) hue-rotate(356deg) brightness(102%) contrast(98%);
    transform: scale(1.1);
  }
  .cls-1:hover, .cls-1:active {
    fill: #ffc600;
  }
  .main-container{
    position: relative;
  }
  /* set top-menu-control */
  .top-menu-control {
    right: 0;
    top: 55px;
    z-index: 900000;
    position: absolute;
    width: 202px;
    font-family: Roboto-Medium, Arial, "Lucida Grande", sans-serif;
    /* box-shadow: 2px 2px 5px rgba(0,0,0,0.2); */
    visibility: hidden;
    font-size: 17px;
    padding: 0px 0px 20px 20px;
  }
  .top-menu-control >div{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  }
  .menu-private-mobile-menu-container .display-navigation-menu .menu-item:active a .collapsible {
    background: var(--color-yellow);
    color: #444;
  }
  /*a Styles*/
  .top-menu-control .sub-menu >li:last-child a{
    border: none;
  }
  .top-menu-control .sub-menu >li:first-child a{
    border-top: 1px solid white;
  }
  .top-menu-control .sub-menu .qtranxs-lang-menu > .sub-menu li:first-child a{
    border-top: 1px solid white;
  }
  .top-menu-control .sub-menu .qtranxs-lang-menu > .sub-menu li a{
    border-top: unset;
    border-bottom: 1px solid #A2A2A3;
  }
  .top-menu-control .sub-menu .qtranxs-lang-menu > .sub-menu li:last-child a{
    border-bottom: unset;
  }
  .top-menu-control .sub-menu li a {
    text-decoration: none;
    padding: 0px 46px 0px 22px;
    height: 47px;
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #A2A2A3;
  }
  .top-menu-control .sub-menu .current-menu-item >a:after, .top-menu-control .sub-menu .current-page-ancestor >a:after {
    content: '';
    position: absolute;
    right: 29px;
    top: 11px;
    width: 5px;
    height: 15px;
    border: solid white;
    border-width: 0px 4px 4px 0;
    transform: rotate(45deg);
  }
  /*ul Styles*/
  .top-menu-control .sub-menu {
    background-color: var(----color-gray-l);
    font-size: 17px;
    list-style-type: none;
    overflow: hidden;
    padding: 0px;
    margin: unset;
    transition: max-height 0.2s ease-out;
    max-height: 0;
  }
  .top-menu-control .sub-menu li > a:active{
   background-color: var(--color-yellow);
   color: #444;
  }

  /* collapsible */
  .top-menu-control .collapsible {
    background-color: var(--color-green-d);
    color: white;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    padding: 0px 0px 0px 22px;
    border-top: 1px solid white;
    display: flex;
    align-items: center;
    height: 47px;
    position: relative;
    box-sizing: border-box;
  }
  .top-menu-control #menu-language-local-mobile >li:nth-child(1)>a>div {
    border-top: 1px solid transparent;
  }
  .top-menu-control .collapsible.hide-icon::after {
    display: none;
  }
  .top-menu-control .collapsible:after {
    content: '\25BC';
    position: absolute;
    color: white;
    right: 26px;
    font-size: 16px;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
  }

  .top-menu-control .clicked-menu:after {
    content: "\25B2";
  }
  .show-top-menu-control{
    visibility: visible;
  }
  .top-menu-control .sub-menu .qtranxs-lang-menu >a{
    display:none;
  }
  .top-menu-control .sub-menu .qtranxs-lang-menu > .sub-menu{
    max-height: unset;
  }

  /****************************************/
  /*               footer                */
  /**************************************/
  .main-footer .bootom-main-menu{
    display: none;
  }
  .main-footer .bootom-main-menu.active{
    display: block;
  }
  .main-footer .container-footer-menu{
    display: flex;
    flex-direction: row;
  }
  .container-footer-menu >ul{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .container-footer-menu>ul>li{
    width: 50%;
    background-color: var(--color-green-d);
    border-bottom: 2px solid white;
    box-sizing: border-box;
  }
  .container-footer-menu>ul>li:nth-last-child(-n+2){
    border-bottom: unset;
  }
  .container-footer-menu>ul>li>a{
    padding-left: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 57px;
    text-transform: uppercase;
    box-sizing: border-box;
    font-size: 18px;
  }
  .container-footer-menu>ul>li:not(.fmb-image):active{
    background-color: var(--color-yellow);
  }
  .container-footer-menu>ul>li:not(.fmb-image):active a{
    color: #444;
  }
  .container-footer-menu>ul>li.fmb-image:active {
    background: var(--color-green-d);
  }
  .container-footer-menu>ul>li>a.fmb-image{
    border: unset;
  }
  .container-footer-menu>ul>li:nth-child(2n+1) {
    border-right: 2px solid white;
  }
  .bootom-main-menu ul>li.current_page_item, .bootom-main-menu ul>li.current-page-ancestor {
    background-color: var(--color-green);
  }
  .main-footer .footer-menu{
    background-color: var(--color-green-d);
    flex:1;
    border-right: 2px solid white
  }
  .main-footer .footer-menu ul li a{
    height: 86px;
    border-top: 2px solid white;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 18px;
  }
  .main-footer .footer-menu >ul >li:active{
    background-color: var(--color-yellow);
  }
  .main-footer .footer-menu >ul >li:active a{
    color: #444;
  }
  .main-footer .footer-menu ul li:first-child a{
    border-top: 2px solid transparent;
  }
  .main-footer .app-menu{
    background-color: var(----color-gray-l);
    width: 27.5%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .main-footer .app-menu >a:first-child{
    margin-bottom: 46%;
  }
  .right-footer-menu, .left-footer-menu{
    display: flex;
    flex-direction: column;
    flex:1;
    font-size: 16px;
  }
  .left-footer-menu{
    border-right: 1px solid white;
  }
  .right-footer-menu{
    border-left: 1px solid white;
  }
  .right-footer-menu .footer-menu-block:last-child,  .left-footer-menu .footer-menu-block:last-child{
    border-bottom: 2px solid transparent;
  }
  .footer-menu-block{
    background-color: var(--color-green-d);
    border-bottom: 2px solid white;
    height: 57px;
    text-transform: uppercase;
    padding-left: 14px;
    display: flex;
    align-items: center;
  }
  .footer-menu-block:active{
    background-color: var(--color-yellow);
    color: #444;
  }
  .app-footer-menu{
    background-color: var(--color-green-d);
    border-top: 2px solid white;
    height: 57px;
    padding-left: 12px;
    display: flex;
    align-items: center;
  }
  .fmb-image{
    justify-content: center;
    height: 59px;
    padding: unset;
    display: flex;
  }
  .fmb-image img{
    filter: invert(15%) sepia(7%) saturate(517%) hue-rotate(199deg) brightness(104%) contrast(81%);
    height: 33px;
  }
  .footer-menu-block.fmb-image:active{
    background: var(--color-green-d);
  }
  .footer-menu-block.fmb-image.fmb-last-image:active{
    background: var(----color-gray-l);;
  }

  .container-footer-menu>ul>li:active.fmb-image a:active img,
  .fmb-image a:active img,
  .main-footer .app-menu >a:active img {
    filter: invert(23%) sepia(71%) saturate(837%) hue-rotate(359deg) brightness(103%) contrast(103%)
  }
  .fmb-image a:first-child{
    margin-right: 19px;
    justify-content: flex-end;
  }
  .fmb-image a:last-child{
    margin-left: 19px;
    justify-content: flex-start;
  }
  .fmb-last-image{
    border-top: 2px solid white;
    border-bottom: unset;
    background-color: var(----color-gray-l);
  }
  .fmb-last-image img{
    filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(73deg) brightness(103%) contrast(103%);
  }
  .fmb-image.fmb-last-image a:first-child{
    margin-right: 40px;
  }
  .fmb-image.fmb-last-image a:last-child{
    margin-left: 40px;
  }

  /* content footer */

  .content-footer{
    background-color: var(----color-gray-l);
    font-size: 15px;
    font-family: 'Roboto-Light';
    color: #BFBFBF;
    padding: 20px 16px 20px 26px;
    border-top: 2px solid white;
  }
  .content-footer .logo-footer img{
    width: 51px;
  }
  .icon-app-footer{
    display: none;
  }
  .icon-app-footer:active img{
    filter: invert(23%) sepia(71%) saturate(837%) hue-rotate(359deg) brightness(103%) contrast(103%);
  }
  .title-footer{
    margin-top: 21px;
    letter-spacing: 0.3px;
    margin-bottom: 5px;
    font-size: 16px;
    font-family: 'Roboto-Bold';
  }
  .header-footer{
    letter-spacing: -0.5px;
  }
  .email-submit{
    margin-top: 6px;
    margin-bottom: 22px;
    display: flex;
    align-items: center;
  }
  .email-submit input {
    padding-left: 9px;
    height: 29px;
    font-size: 15px;
    outline: unset;
    width: 61%;
    border-radius: 3px;
  }
  .email-submit input.wpcf7-submit {
    height: 35px;
    background: none;
    border-radius: 4px;
    outline: none;
    margin-left: 14px;
    border: none;
    background-color: var(--green-mobile);
    color: white;
    width: 22%;
  }
  .email-submit input.wpcf7-submit:hover, .email-submit input.wpcf7-submit:active, .email-submit input.wpcf7-submit:focus {
    background-color: var(--color-green-d);
  }
  .social-network img{
    width: 12%;
    margin-right: 13.4%;
    max-width: 68px;
  }
  a .img-hover {
    display: none;
  }
  a:active .img-hover {
    display: inline-block;
  }
  a:active .img-block {
    display: none;
  }
  .social-network >a:last-child img{
    margin-right: unset;
  }
  .title-summary{
    margin-top: 18px;
    font-size: 17px;
    font-family: 'Roboto-Bold';
    margin-bottom: 8px;
  }
  .content-summary{
    letter-spacing: -0.04px;
    line-height: 1.2;
    font-size: 14px;
  }
  .title-founder{
    font-size: 17px;
    font-family: 'Roboto-Bold';
    margin-top: 26px;
    margin-bottom: 6px;
  }
  .logo-inspireventures{
    margin-bottom: 20px;
  }
  .logo-inspireventures img{
    width: 26%;
  }
  .line-footer{
    width: calc(100% - 6px);
    height: 1px;
    background-color: #9E9DA0;
  }
  .container-right{
    margin-top: 25px;
    margin-bottom: 21px;
  }
  .container-right .block{
    display: flex;
    align-items: center;
    margin-bottom: 13px;
  }
  .container-right .block img{
    width: 30px;
  }
  .container-right .block .text{
    margin-left: 15px;
    font-size: 14px;
  }
  .container-right .block .text a {
    color: #BFBFBF;
  }
  .bottom-footer{
    font-size: 14px;
  }
  /****************************************/
  /*             container               */
  /**************************************/
  /****************************************/
  /*             Front Page              */
  /**************************************/
  .content-menu-main {
    background: var(--green-mobile);
  }
  .content-menu-item {
    border-bottom: 2px solid #fff;
    font-family: 'Roboto-Bold';
    font-size: 20px;
    color: #fff;
    height: 105px;
    box-sizing: border-box;
  }
  .content-menu-item:nth-of-type(1) {
    border-top: 2px solid #fff;
  }
  .content-menu-item .menu-item-img {
    width: 157px;
    height: 100%;
  }
  .content-menu-item .menu-item-img img {
    width: 100%;
    height: 100%;
  }
  .content-menu-item .menu-item-text {
    flex: 1;
    padding-left: 20px;
    background: var(--green-mobile);
    align-items: center;
    text-transform: uppercase;
  }
  .content-menu-item .menu-item-text:hover {
    background: var(--color-yellow);
    color: #444;
  }/**************************************/

  /* header container*/
  .top-main-header{
    background-color: rgba(204, 216, 222, 0.85);
    padding: 15px 20px;
    z-index: 99;
  }
  .top-main-header .header{
    display: flex;
    margin-bottom: 3px;
  }
  .top-main-header .header .title{
    font-family: 'BebasNeue-Bold';
    font-size: 34px;
    flex: 1;
    color: var(--color-green-d);
    margin: 0;
  }
  .top-main-header .header-content{
    font-family: 'Roboto-Regular';
    font-size: 17px;
    line-height: 1.3;
    color: #241f20;
  }

  .title-page {
    background: #ccd8de;
    padding: 15px 20px;
  }
  .title-page .title-detail {
    font-size: 17px;
    color: #241f20;
    font-family: 'Roboto-Regular';
  }
  #th-site .title-page .title-detail {
    font-family: 'DB Heavent';
    font-size: 22px;
  }
  .title-page .title {
    font-family: 'BebasNeue-Bold';
    font-size: 34px;
    color: #007500;
    text-transform: uppercase;
  }
  #th-site .title-page .title {
    font-family: 'DB Heavent';
    font-size: 40px;
    font-weight: bold;
  }
  .display-navigation-menu {
    display: none;
  }
  .display-navigation-menu.active {
    display: block;
  }
  .site-title, .site-description {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
  }
  button.alm-load-more-btn.more.btn-loadmore {
    text-indent: 99999px;
    width: 200px;
    overflow: hidden;
  }
  button.alm-load-more-btn.more.btn-loadmore:after {
    content: "More Results";
    position: absolute;
    text-indent: 0px;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
  }
  #th-site button.alm-load-more-btn.more.btn-loadmore:after {
    content: "เพิ่มเติม";
    position: absolute;
    text-indent: 0px;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
  }
  #id-site button.alm-load-more-btn.more.btn-loadmore:after {
    content: "Lebih Banyak";
    position: absolute;
    text-indent: 0px;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
  }
  button.alm-load-more-btn.more.btn-loadmore.done:after {
    content: "Less Results";
    position: absolute;
    text-indent: 0px;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
  }
  #th-site button.alm-load-more-btn.more.btn-loadmore.done:after {
    content: "ไม่มีบล็อกเพิ่มเติม";
    position: absolute;
    text-indent: 0px;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
  }
  #id-site button.alm-load-more-btn.more.btn-loadmore.done:after {
    content: "Lebih Sedikit";
    position: absolute;
    text-indent: 0px;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
  }
  #fc_frame{
    display: none;
  }
  .wpcf7-form-control-wrap .wpcf7-not-valid-tip , .wpcf7-response-output{
   display: none;
  }
  .email-submit .screen-reader-response[role=alert]{
    background-color: var(--color-yellow);
    color: var(--main-text-color);
    border: unset;
    font-size: 17px;
    font-family: "Roboto-Light";
    border-radius: 2px;
    padding: 4px 10px;
    margin-bottom: 7px;
    margin-right: 28px;
  }
  .modal-popup {
    display: none;
    position: fixed;
    z-index: 9999;
    padding-top: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.8);
  }
  .modal-popup-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border-radius: 8px;
    width: 90%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
  }
  .modal-popup-content ul {
    margin: 0 0 0 10px;
    padding: inherit;
  }
  .modal-popup-content li{
    list-style-type: inherit;
  }
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
  }
  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }
  .close {
    color: white;
    float: right;
    font-size: 40px;
    font-weight: bold;
    line-height: 24px;
    padding-right: 6px;
    padding-top: 3px;
  }
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  .modal-popup-header {
    padding: 2px 16px;
    background-color: #00ac2b;
    color: white;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-top: -1px;
  }
  .modal-popup-body {padding: 8px 16px;}
  .modal-popup-footer {
    padding: 10px 16px;
    background-color: #00ac2b;
    color: white;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .modal-popup-header h1, .modal-popup-header p {
    font-size: 22px;
    font-family: 'Roboto-Regular', sans-serif;
    font-weight: 400;
    margin: 10px 0;
  }
  #myBtn {
    cursor: pointer;
  }
  button.btn-custom {
    background-color: #4285f4!important;
    color: #fff;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    padding: .84rem 2.14rem;
    font-size: .81rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    margin: .375rem;
    border: 0;
    -webkit-border-radius: .125rem;
    border-radius: .125rem;
    cursor: pointer;
    text-transform: uppercase;
    white-space: normal;
    word-wrap: break-word;
    color: #fff;
  }
  button.btn-custom:hover {
    -webkit-box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
    outline: 0;
  }
  .modal-popup-body h2, .modal-popup-body p {
    font-size: 16px;
    font-family: 'Roboto-Regular', sans-serif;
    font-weight: 400;
    color: #212529;
    margin-bottom: 8px;
  }
  .modal-popup-body h2 {
    padding-bottom: 8px;
    border-bottom: 1px solid #00ac2b;
    margin-top: 0;
  }
  .modal-popup-footer {
    text-align: right;
  }
  div#map {
    width: 100%;
  }
  p.qtranxs-available-languages-message {
    display: none;
  }
  .text-no-wrap{
    white-space: nowrap;
  }
  .icon-open img {
    height: 18px;
    margin-left: 10px;
    position: relative;
    bottom: -1px;
  }
  #th-site .icon-open img, #th-site-en .icon-open img {
    height: 16px;
    margin-left: 10px;
    position: relative;
    bottom: -1px;
  }
  #id-site ul#menu-primary-footer-mobile li#menu-item-28187,
  #th-site ul#menu-primary-footer-mobile li#menu-item-14025 {
    display: none;
  }
  #id-site-en ul#menu-primary-footer-mobile li#menu-item-23361,
  #th-site-en ul#menu-primary-footer-mobile li#menu-item-12874 {
    display: none;
  }
  .business-program-mb .bg-title.clearflex
  .lh-business-program-mb .bg-title.clearflex {
    flex-direction: row !important;
  }
  .lh-business-program-mb .bg-title>span, .business-program-mb .bg-title>span {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .clearflex .icon-open img {
    bottom: 1px !important;
  }
  .modal-popup-body a {
    color: #999999;
  }
  span.red {
    background: red;
     border-radius: 0.8em;
    -moz-border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 1.6em;
    margin-right: 15px;
    text-align: center;
    width: 1.6em; 
  }
  span.grey {
    background: #cccccc;
    border-radius: 0.8em;
    -moz-border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    color: #fff;
    display: inline-block;
    font-weight: bold;
    line-height: 1.6em;
    margin-right: 15px;
    text-align: center;
    width: 1.6em; 
  }
  span.green {
    background: #5EA226;
    border-radius: 0.8em;
    -moz-border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 1.6em;
    margin-right: 15px;
    text-align: center;
    width: 1.6em; 
  }
  span.blue {
    background: #5178D0;
    border-radius: 0.8em;
    -moz-border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 1.6em;
    margin-right: 15px;
    text-align: center;
    width: 1.6em; 
  }
  span.pink {
    background: #EF0BD8;
    border-radius: 0.8em;
    -moz-border-radius: 0.8em;
    -webkit-border-radius: 0.8em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 1.6em;
    margin-right: 15px;
    text-align: center;
    width: 1.6em; 
  }
  span.icon-open {
    cursor: pointer;
  }

  .language-top-menu img{
    width: 28px;
    padding-right: 8px;
    opacity: 0.6;
  }
  .language-top-menu img.active{
    opacity: 1;
  }
  #th-site #menu-language-local-mobile .qtranxs-lang-menu-item-th a {
    text-indent: -999px;
    font-size: 0;
  }
  #th-site #menu-language-local-mobile .qtranxs-lang-menu-item-th a:before {
    content: "ไทย";
    text-indent: 0;
    font-size: 20px;
  }

  /* loading icon deliveree*/
  .Loanding-Deliveree{
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 100011;
    background: rgba(0, 0, 0, 0.8);
    align-items: flex-start;
    justify-content: center;
    display: none;
  }
  .Loanding-Deliveree .Locating-Drivers{
    margin: auto;
  }
  .Loanding-Deliveree .Loader-Icon{
    background: rgb(63, 174, 41);
    width: 116px;
    height: 116px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    position: relative;
  }
  .Loanding-Deliveree .Logo{
    display: flex;
    align-items: center;
    position: relative;
    height: inherit;
    padding: 0px 20px;
  }
  .Loanding-Deliveree .Logo img{
    width: auto;
    height: 68px;
  }
  @keyframes rotating {
    0%{-ms-transform:rotate(0deg);-webkit-transform:rotate(0deg);transform:rotate(0deg)}
    to{-ms-transform:rotate(1turn);-webkit-transform:rotate(1turn);transform:rotate(1turn)}
  }
  .Loanding-Deliveree .Line-Animation{
    width: 94px;
    height: 94px;
    border: 1px dashed rgb(255, 255, 255);
    position: absolute;
    top: 10px;
    left: 10px;
    border-radius: 100px;
    animation: rotating 6s linear infinite;
  }
  .show-video .play-video-btn .play-button-bg {
    fill: #ff0000;
    fill-opacity: 1;
  }
  .video-box img {
    height: 100%;
    width: 100%;
  }
  .content-area .alert-none {
    max-width: 310px;
    margin: 25px auto;
  }
  .page-mobile .select-bar .list-item li p {
    pointer-events: none;
  }
  .extra-service .service-panel p.content_remark {
    margin-top: 5px;
    font-size: 11px;
  }
  #th-site .extra-service .service-panel p.content_remark {
    margin-top: 5px;
    font-size: 14px;
  }
  .open-livechat-custom-container.show{
    visibility: visible;
  }
  .open-livechat-custom-container {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    top: 66px;
    left: 20px;
    text-align: right;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.26);
    background-color: var(--color-yellow);
    border-radius: 7px;
  }
  .open-livechat-custom-box {
    position: relative;
    display: flex;
    flex-flow: wrap column;
    padding: 15px;
  }
  .open-livechat-custom-box a {
    border: 1px solid black;
    border-radius: 3px;
    padding: 8px 15px;
    text-align: center;
    font-family: 'Roboto-Regular';
    font-size: 16px;
    color: black
  }
  #th-site .open-livechat-custom-box a{
    font-family: 'DB Adman X';
    font-size: 21px;
    font-weight: 500
  }
  .open-livechat-custom-box:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: -15px;
    left: 0;
    box-sizing: border-box;
    border: 10px solid black;
    border-color: transparent transparent var(--color-yellow) var(--color-yellow);
    transform-origin: 0 0;
    /* box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, .26) */
  }
  .open-livechat-custom-box .livechat-customer {
    margin-bottom: 10px;
  }
  .open-livechat-custom-box a:hover {
    background-color: white;
  }
  .open-livechat-custom-container img {
    width: 45px;
    margin-top: 10px;
  }
  .livechat-driver-container {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.5);
    font-size: 16px;
    font-family: "Roboto-Regular";
    z-index: 2147483601;
  }
  #th-site .livechat-driver-container{
    font-size: 26px;
    font-family: "DB Heavent";
    font-weight: 400;
  }
  .livechat-driver-box {
    width: 314px;
    min-height: 153px;
    background-color: var(--green-mobile);
    color: #fff;
    border-radius: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom -15px left 5px;
  }
  .livechat-driver-container .box-right {
    padding: 17px 5px 17px 111px;
    text-align: center;
    line-height: 1.6;
  }
  #th-site .livechat-driver-container .box-right{
    line-height: 1.1;
  }
  .livechat-driver-container a{
    color: var(--color-hyperlink-green);
    text-decoration: underline;
  }
  .livechat-driver-container #count-down {
    font-size: 24px;
  }
  #th-site .livechat-driver-container #count-down{
    font-size: 35px;
  }
  .livechat-driver-container .close-countdown-popup {
    background-color: #9b9b9b;
    color: #fff;
    width: 314px;
    margin-top: 10px;
    padding: 7px 0;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    font-size: 17px;
  }
  #th-site .livechat-driver-container .close-countdown-popup{
    font-size: 26px;
    padding: 4px 0;
  }
  .livechat-driver-container .close-countdown-popup:active {
    background-color: var(--main-text-color);
  }
  .get-answers-from{
    padding: 0 12px;
  }
  /* Popup-Web  */
  .Popup-Web {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.5);
    font-family: "Roboto-Regular";
    z-index: 2147483601;
  }
  #th-site .Popup-Web{
    font-family: "DB Heavent";
  }
  .Popup-Web .Popup-Container{
    top: inherit;
    bottom: inherit;
    align-self: center;
    margin: auto;
    width: 100%;
    max-width: 314px;
    font-size: 19px
  }
  #th-site .Popup-Web .Popup-Container, #th-site .Popup-Web .Popup-Title{
    font-size: 28px;
    font-weight: 400;
  }
  .Popup-Web .Popup-Main{
    background: var(--green-mobile);
    color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }
  .Popup-Web .Popup-Icon{
    background: white;
    height: 64px;
    border-radius: 64px;
    justify-content: center;
    align-items: center;
    left: 50%;
    margin: -32px 0 0 -32px;
    width: 64px;
    position: absolute;
    box-shadow: 1px 1px 25px #0f370f;
    display: flex;
    flex-direction: column;
  }
  .Popup-Web .Popup-Icon img {
    width: auto;
    height: 40px;
  }
  .Popup-Web .Popup-Center {
    margin-top: 47px;
    padding: 0 15px 15px;
    box-sizing: border-box;
    width: 100%;
    flex: 1;
  }
  .Popup-Web .Popup-Normal{
    width: 100%;
    box-sizing: border-box;
  }
  .Popup-Web .Popup-Title{
    color: var(--color-yellow);
    margin: 0 0 12px;
    font-size: 19px;
    text-align: center;
    font-weight: 400;
  }
  .Popup-Web .Popup-Content{
    padding: 0;
    text-align: center;
    line-height: 24px;
    margin: 0;
  }
  .Popup-Web .Popup-Action{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px -5px 0;
  }
  .Popup-Web .Popup-Action a{
    text-transform: capitalize;
    font-weight: 400;
    flex: 1;
    margin: 0 5px;
    line-height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    outline: 0;
    width: 100%;
    border: 0 none;
    cursor: pointer;
  }
  .Popup-Web .Popup-Action .Cancel{
    color: #4b4b4b;
    background-color: #cecece;
  }
  .Popup-Web .Popup-Action .Confirm{
    color: var(--green-mobile);
    background-color: white;
  }
  .Popup-Web .Popup-Action .Confirm:hover{
    color: white;
    background-color: var(--color-yellow);
  }

  /*  */
  .driver-hotline-popup .Confirm img{
    margin-right: 9px;
    width: 22px;
  }
}