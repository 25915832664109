// If you want to override variables do it here
@import "variables";

// If you want to add something do it here
@import "common";
@import "common_mobile";
@import "main";
@import "responsive";

// ie fixes
@import "ie-fix";

@import "override-bootstrap";

// Override location of Google Auto Suggestion dropdown
.pac-container.pac-container.pac-container {
  top: 100% !important;
  left: 0 !important;

  width: 100% !important;
  margin-top: 0;
}
