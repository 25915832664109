.btn-success {
  border-color: var(--color-green-l);
  background-color: var(--color-green-l);

  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    box-shadow: none;
    border-color: var(--color-green);
    background-color: var(--color-green);
  }

  &:disabled {
    border-color: var(--color-green-l);
    background-color: var(--color-green-l);
  }
}

// Override form text
.form-text {
  color: #a1a1a1 !important;
  font-style: italic;
  font-size: 14px;
}

// Invalid input field
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #ce1126;
  background-color: #f5cfd4;
  background-image: none;
}

// Override disable text color
.form-control:disabled,
.form-control[readonly] {
  color: #999;
}

// Form input
.form-control {
  height: 45px;
  border-color: var(--color-gray-border);
  -webkit-appearance: none;
  font-size: 16px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: var(--color-gray-background);
}

.form-control:focus {
  border-color: var(--color-green);
  box-shadow: 0 0 0 0.2rem rgba(0, 173, 15, 0.25);
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: var(--color-red);
  background-color: var(--color-light-red-background);
}

.input-group-text {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-color: var(--color-gray-border);
}

// *********************************
// *       CUSTOM RADIO            *
// *********************************
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--color-green);
  background-color: var(--color-green);
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 173, 15, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: var(--color-green);
}

.custom-radio {
  margin-bottom: 0.5rem;
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 173, 15, 0.5);
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 173, 15, 0.5);
}

// *********************************
// *             BUTTON            *
// *********************************
.btn {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.btn-link {
  padding: 0 0.75rem;
  color: var(--color-green);

  &:hover {
    color: var(--color-green-d);
  }
}

// *********************************
// *             MODAL             *
// *********************************
.modal-backdrop.show {
  opacity: 0.8;
}
