/* 404 */
.page-not-found {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-not-found .image-404 {
  max-width: 520px;
  margin: auto;
  display: block;
  width: 100%;
  max-height: 417px;
  height: 67vh;
  object-fit: contain;
}
.page-not-found .text-error {
  text-align: center;
  font-family: "Roboto-Medium";
  font-size: 35px;
  margin-bottom: 30px;
  color: #474747;
}
.page-not-found .button-go-to-home {
  min-width: 140px;
  max-width: 300px;
  margin: auto;
  text-align: center;
  padding: 0 10px;
  align-items: center;
  background-attachment: scroll;
  background-clip: border-box;
  background-color: var(--color-green);
  border-bottom-color: #fff;
  border-radius: 10px;
  border-bottom-style: none;
  border-bottom-width: 0;
  border-image-outset: 0px;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: #fff;
  border-left-style: none;
  border-left-width: 0;
  border-right-color: #fff;
  border-right-style: none;
  border-right-width: 0;
  border-top-color: #fff;
  border-top-style: none;
  border-top-width: 0;
  box-shadow: rgba(63, 174, 41, 0.17) 0px 13px 25px 0px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 24px;
  height: 48px;
  justify-content: center;
  line-height: 48px;
  outline-color: #fff;
  outline-style: none;
  outline-width: 0;
  padding-bottom: 0;
  text-decoration: none;
  font-family: "Roboto-Regular", sans-serif;
}
.page-not-found .button-go-to-home:hover {
  color: #474747;
  background-color: var(--color-yellow);
}

.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px !important;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mt25 {
  margin-top: 25px;
}
.mt30 {
  margin-top: 30px;
}

.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mtb20 {
  margin-bottom: 20px;
}
.mb25 {
  margin-bottom: 25px;
}
.mb30 {
  margin-bottom: 30px;
}

.mr5 {
  margin-right: 5px;
}
.mr10 {
  margin-right: 10px;
}
.mr15 {
  margin-right: 15px;
}
.mr20 {
  margin-right: 20px;
}
.mr25 {
  margin-right: 25px;
}
.mr30 {
  margin-right: 30px;
}

.ml5 {
  margin-left: 5px;
}
.ml10 {
  margin-left: 10px;
}
.ml15 {
  margin-left: 15px;
}
.ml20 {
  margin-left: 20px;
}
.ml25 {
  margin-left: 25px;
}
.ml30 {
  margin-left: 30px;
}

.pd5 {
  padding: 5px;
}
.pd10 {
  padding: 10px;
}
.pd20 {
  padding: 20px;
}
.pt10 {
  padding-top: 10px;
}
.pt20 {
  padding-top: 20px;
}
.no-border-b {
  border-bottom: none !important;
}
.modal-popup.visible {
  display: flex;
}

.flex-one {
  flex: 1;
}
.modal-popup .close {
  color: #fff;
  float: right;
  font-size: 36px;
  font-weight: 700;
  line-height: 24px;
  padding-right: 10px;
  padding-top: 10px;
}

@media only screen and (min-width: 768px) {
  .modal-popup .close {
    padding-right: 16px;
    padding-top: 16px;
  }
}

/*
 * Modal
 */

.Modal {
  display: none;
}

.Modal.visible {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.Modal.visible.full {
  left: 0;
  z-index: 999;
}

.Modal.visible.page {
  left: 236px;
  z-index: 11;
}

.locating-drivers {
  margin: auto;
}

.Loader {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Loader .Overlay {
  background: rgba(255, 255, 255, 0.2);
}

.Loader-Icon {
  background: #3fae2a;
  width: 116px;
  height: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  position: relative;
}

.Loader-Icon img {
  width: auto;
  height: 68px;
}

.Loader-Icon:after {
  content: "";
  width: 94px;
  height: 94px;
  border: 1px dashed #fff;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 100px;
  -webkit-animation: rotating 6s linear infinite;
  -moz-animation: rotating 6s linear infinite;
  -ms-animation: rotating 6s linear infinite;
  -o-animation: rotating 6s linear infinite;
  animation: rotating 6s linear infinite;
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes sonarWave {
  from {
    opacity: 0.4;
  }
  to {
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes sonarWave2 {
  from {
    opacity: 0.4;
  }
  to {
    transform: scale(6);
    opacity: 0;
  }
}

@keyframes sonarWave3 {
  from {
    opacity: 0.4;
  }
  to {
    transform: scale(9);
    opacity: 0;
  }
}

.Common .Popup {
  align-self: center !important;
  z-index: 2;
}

.Common .Box {
  position: relative;
  width: 100%;
}

.Common .Box {
  display: flex;
  flex-direction: column;
}

.Common .Box-Content {
  width: 100%;
  flex: 1;
}

.Common .Box-Content {
  background: #3fae29;
  color: white;
  margin: 5px 10px;
  box-sizing: border-box;
  width: auto;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  padding: 12px;
}

.Common .Normal {
  width: 100%;
  box-sizing: border-box;
  min-width: 300px;
  max-width: 350px;
}

.Common .Popup-Booking-Normal h5 {
  margin: 0 0 10px 0;
  font-size: 17px;
  text-align: left;
}

.Common .Popup-Booking-Custom * {
  font-size: 15px;
}

.cur-pointer {
  cursor: pointer;
}

.cur-default {
  cursor: default;
}

.cur-default-important {
  cursor: default !important;
}

.cur-auto {
  cursor: auto;
}

#root .default-color,
.default-color {
  color: #444444;
}

.flex-index {
  flex: 1 !important;
}

.hide {
  display: none;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a1a1a1;
  font-style: italic;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a1a1a1;
  font-style: italic;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a1a1a1;
  font-style: italic;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.5;
}
.show {
  display: block;
}

.form-control {
  font-family: "Roboto-Regular";
}
.l-container.push {
  background-color: transparent;
}

.site {
  width: 100%;
  height: 100%;
}

.site > .site-header {
  padding: unset;
}

.site .l-container .main-page {
  display: flex;
  align-items: center;
  height: 100%;
}

.font-size__22 {
  font-size: 22px;
}

.font-size__24--imp {
  font-size: 24px !important;
}

.default-color-imp {
  color: #212529 !important;
}

.mobile-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 424px;
  height: 720px;
}
.mobile-screen > section {
  height: 100%;
}
.mobile-screen .menu.navbar-2 ~ section,
.mobile-screen .menu.navbar-2 + section {
  height: calc(100% - 58px);
  margin-top: 58px;
}
.mobile-screen .l-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.l-content-footer {
  padding: 10px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  margin: 0;
}
.l-content-footer.l-content-footer_sectiona {
  display: block;
}
.l-content-footer_padding {
  height: 118px;
}
.l-content-footer button,
.l-content-footer_finish button:hover,
.l-content-footer_finish button:active,
.l-content-footer_finish button:focus {
  width: 100%;
  height: 54px;
  background-color: var(--color-white);
  color: var(--color-green-l);
  border-radius: 4px;
  border: none;
  font-size: 22px;
  border: 2px solid var(--color-green-l);
  font-family: var(--font-robo-b);
  line-height: 1.2;
  outline: none;
  transition: all 0.25s ease-in-out 0s;
}

.l-content-footer button:last-child,
.l-content-footer button:last-child:hover,
.l-content-footer button:last-child:active,
.l-content-footer button:last-child:focus {
  background-color: var(--color-green-l);
  color: var(--color-white);
  border: unset;
}
.l-content-footer button:disabled {
  opacity: 0.5;
}
.l-content-footer_finish button:nth-child(2) {
  margin-top: 10px;
}

.l-content-footer_description {
  font-size: 17px;
  padding: 10px 20px 30px 20px;
}

.l-content-footer_description a {
  color: var(--color-green-l);
  font-weight: 400;
  font-family: var(--font-robo-m);
}

a {
  color: var(--color-green-l);
}

a:hover {
  color: var(--color-green);
  text-decoration: none;
}

section {
  position: relative;
}

.mobile-screen .menu {
  display: block !important;
  width: 100%;
  z-index: 2;
  position: fixed;
  top: 0;
}

.button-more,
.cur-pointer {
  cursor: pointer;
}

/*------------------------------------------------------------
  CONTENT
------------------------------------------------------------*/
.l-content {
  font-family: var(--font-robo-r), sans-serif;
  position: relative;
  color: var(--color-gray);
  line-height: normal;
}

#th-site .l-content,
#th-site-en .l-content {
  font-family: "DB Adman X";
}

/*--------------------------------------------------
  C-INTRO
--------------------------------------------------*/
.c-intro {
  position: absolute;
  width: 100%;
  color: #1e7a40;
  background-color: rgba(204, 204, 204, 0.8);
  top: 0;
  z-index: 2;
  border-top: 1px solid #dbdbdb;
  border-bottom: 2px solid #3fae2b;
  padding: 18px 0;
}

.c-intro img {
  width: 90%;
}

.c-intro-DLVR {
  background-size: cover;
  height: 58px;
  min-height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #959595;
  border-bottom: 1px solid #d9d9d9;
}

.c-intro-DLVR .title {
  font-size: 26px;
  font-weight: 900;
  margin: 0;
  color: #257216;
}
#th-site .c-intro-DLVR .title {
  font-weight: 500;
}

#th-site .c-intro-DLVR .title,
#th-site-en .c-intro-DLVR .title {
  font-family: "DB Heavent";
  font-size: 32px;
}

.c-bg {
  background: url("../images/bg-title.png");
  background-size: cover;
}

.img-car p {
  margin: 0;
}

.logo-navbar {
  position: absolute;
  width: 70%;
  height: 100%;
  margin-left: 15%;
}

.logo-navbar img {
  display: block;
}

/*--------------------------------------------------
  C-MAINVIEW And C-PAGE
--------------------------------------------------*/
.c-mainview {
  position: relative;
  background: #fff;
  scroll-behavior: smooth;
  overflow-x: auto;
  height: 100%;
}
.c-mainviewfull {
  background-image: url("../images/bgLogin.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  color: var(--color-white);
  font-size: 35px;
  text-align: center;
  position: relative;
  scroll-behavior: smooth;
  overflow-x: auto;
  height: 100%;
}
@media (min-width: 767px) {
  .mobile-screen {
    width: 100%;
    margin: auto;
    box-sizing: border-box;
  }
}
/*--------------------------------------------------
  C-RESULT - C-MODAL
--------------------------------------------------*/

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  cursor: pointer;
}

.navbar-1 {
  justify-content: center;
  background-color: #3fae2b;
  height: 55px;
  display: none;
  align-items: center;
}

.navbar-1 .logo {
  width: auto;
  height: 30px;
}

.navbar-2 .back-button {
  width: 30px;
}

.navbar-2 .logo {
  height: 40px !important;
  margin: 10px auto;
}
.inspire-dropdown.right.open .dropdown-options {
  right: 4px;
}

.menu {
  background-color: #3fae2a;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
}
.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  box-sizing: border-box;
}
.col-xs-12 {
  flex: 0 0 100%;
}
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12 {
  position: relative;
  min-height: 1px;
}
.navbar::after {
  content: "";
  display: table;
  clear: both;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
.navbar {
  padding: 0;
  display: flex;
  height: 58px;
  align-items: stretch;
  position: relative;
}
.bg-primary {
  color: #fff !important;
  background-color: #3fae2a !important;
}
@media (min-width: 544px) {
  .navbar {
    border-radius: 0.25rem;
  }
}
.navbar .collapse {
  display: flex !important;
  align-items: stretch;
  flex: 1;
}
@media (max-width: 991px) {
  .navbar .collapse .navbar-nav {
    flex: 1;
  }
}
.navbar .collapse .navbar-nav {
  display: flex;
  flex: 3;
  align-items: flex-start;
  justify-content: center;
}
.nav {
  padding-left: 0;
  margin: 0;
  list-style: none;
}
.navbar .collapse .navbar-nav .nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-nav .nav-item {
  float: left;
}
@media (max-width: 991px) {
  .navbar .collapse .navbar-nav.pull-right {
    flex: 3;
  }
}
.navbar .collapse .navbar-nav.pull-right {
  align-items: flex-end;
}
.pull-right {
  float: right;
}
.nav-item.nav-dropdown {
  position: relative;
}
.inspire-dropdown {
  text-align: left;
  width: 100%;
}
.inspire-dropdown .active-item {
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
}
.nav-item.nav-dropdown .item-title {
  text-transform: uppercase;
  padding: 0;
  display: block;
  cursor: pointer;
}
.navbar-2 .row .collapse .active-item .item-title .fa-home {
  margin-top: 5px;
  font-size: 28px;
}
.nav-item.nav-dropdown .dropdown-options {
  top: 44px;
}
.mobile-on-page-menu li a:hover {
  background: var(--color-yellow);
  text-decoration: none;
  color: #222222;
}
.inspire-dropdown.green .dropdown-options {
  background: #0e730f;
}
.inspire-dropdown .dropdown-options {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

.inspire-dropdown.green .dropdown-options .menu-item a,
.inspire-dropdown.green .dropdown-options .menu-item button {
  border-bottom: 1px solid rgba(34, 34, 34, 0.3);
  color: var(--color-white);
}
.inspire-dropdown.green .dropdown-options .menu-item a:hover,
.inspire-dropdown.green .dropdown-options .menu-item button:hover {
  color: #3fae2a;
  text-decoration: none;
}
.navbar .collapse .navbar-nav .nav-item a,
.navbar .collapse .navbar-nav .nav-item button {
  display: inline-block;
  padding: 0 20px;
}
.navbar .collapse .navbar-nav .nav-item button {
  outline: 0;
  background: transparent;
  border: 0 none;
}
.inspire-dropdown.open .dropdown-options .menu-item a,
.inspire-dropdown.open .dropdown-options .menu-item button {
  padding: 14px 20px !important;
  border-bottom: 1px solid rgba(34, 34, 34, 0.3);
  color: var(--color-white);
  display: block;
  width: 100%;
  font-family: "Roboto-Light";
  text-align: left;
}
#th-site .inspire-dropdown.open .dropdown-options .menu-item a,
#th-site-en .inspire-dropdown.open .dropdown-options .menu-item a {
  font-family: "DB Heavent";
  font-size: 20px;
}
.inspire-dropdown.open .dropdown-options {
  display: block;
  position: absolute;
  left: 0;
  background: var(--color-white);
  z-index: 2000;
  width: 100%;
  min-width: 200px;
  box-shadow: #ccc 0 1px 1px;
}
.inspire-dropdown.right.open .dropdown-options {
  right: 4px;
}
.inspire-dropdown.right.open .dropdown-options {
  right: 0;
  left: auto;
}
.inspire-dropdown.green .dropdown-options::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #0e730f;
  position: absolute;
  top: -8px;
  right: 12px;
}
.inspire-dropdown.green .dropdown-options {
  background: #0e730f;
}
.modal-popup-content .close {
  opacity: 1;
}
@media (max-width: 767px) {
  .modal-popup-content {
    width: calc(100% - 24px) !important;
  }
}

@media (min-width: 768px) {
  .mobile-on-page-menu {
    height: 498px;
    overflow-y: scroll;
  }
}

/*
 * Deliveree Onboarding Style
 */

/* Login */
.c-mainview-login {
  padding: 4rem 0 2rem 0;
  margin: 0;
  font-family: var(--font-robo-b);
  text-align: center;
}
.c-login-meta {
  font-size: 22px;
}
.c-login-description {
  padding: 0 20px;
  margin-top: 5rem;
}
.c-login-description h2 {
  font-size: 30px;
  padding: 0 36px;
}
.c-login-description button {
  font-size: 22px;
  margin-top: 174px;
  width: 100%;
  max-width: 384px;
  height: 54px;
  background-color: var(--color-green-l);
  border-radius: 4px;
  color: var(--color-white);
  border: none;
  margin-top: 20px;
}
/* .c-login-description button:hover{
  background-color: var(--color-yellow);
  color: var(--color-gray)
} */
.c-login-approval {
  font-size: 18px;
  margin-top: 60px;
}
.c-mainview-login .c-login-link {
  font-family: var(--font-robo-m);
  font-size: 18px;
  color: #3eae29;
  text-decoration: underline;
  cursor: pointer;
}
.c-card-box {
  display: flex;
  color: #fff;
  align-items: center;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 40px;
  text-transform: capitalize;
  font-family: "Roboto-Medium";
}
.c-card-box span {
  flex: 1;
}

/* SelectArea */
.c-list {
  border-bottom: 1px solid #a0a0a0;
}
.c-mainview-list {
  font-size: 24px;
  font-family: "Roboto-Regular";
}

/* VerifyNumber*/
.c-verify-text {
  text-align: center;
  padding: 50px 52px 30px 52px;
  font-size: 20px;
  line-height: normal;
}
.c-verify-number {
  display: flex;
}
.c-verify-number input {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.c-verify-card_box {
  display: flex;
  /* justify-content: space-between; */
}
.c-verify-card {
  margin: 0 auto;
}
.c-verify-card_box > span {
  position: relative;

  width: 48px;
  height: 48px;
  border-radius: 3px;
  border: solid 1px #dbdbdb;

  background-color: #ffffff;
  text-align: center;
  font-size: 24px;
  line-height: 48px;
}

.c-verify-card_box > span:empty::before {
  content: "";
  position: absolute;
  top: 22px;
  left: 22px;

  width: 4px;
  height: 4px;
  border-radius: 50%;

  background-color: #a1a1a1;
}
.c-verify-card_box > span:not(:last-child) {
  margin-right: 10px;
}
.c-verify-resend {
  font-size: 18px;
  margin-top: 20px;
  padding: 0 48px;
  text-align: center;
  margin-bottom: -80px;
  padding-bottom: 80px;
}
.c-verify-resend_code {
  color: #3eae29;
  cursor: pointer;
  font-family: var(--font-robo-m);
  text-decoration: underline;
  position: relative;
  display: inline-block;
}
.c-verify-resend_code:hover {
  color: #085f10;
}
.c-verify-invalid {
  color: red;
  font-size: 17px;
  margin-top: 20px;
  text-align: center;
  padding: 0 48px;
}

/* signin */
.c-signin-invalid {
  color: red;
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
  padding: 0 43px;
  margin-bottom: -80px;
  padding-bottom: 80px;
}

/* confirm page */
.c-confirm-img {
  margin-top: 64px;
  text-align: center;
}
.c-confirm-img img {
  height: 88px;
}
.c-confirmtion-description {
  padding-top: 13px;
  line-height: 1.15;
  font-size: 20px;
  text-align: center;
  padding: 0px 30px;
  margin-top: 30px;
}

.c-confirm-policy {
  margin: 85px 20px 0px 20px;
}
.c-confirm-policy_checbox label::before {
  width: 18px;
  height: 18px;
  background-color: #fff0;
  border: var(--color-gray) solid 1.5px;
  top: 2px;
  box-sizing: border-box;
}
.c-confirm-policy_checbox label::after {
  width: 18px;
  height: 18px;
  background: no-repeat 50%/11px 11px;
  top: 2px;
}
.c-confirm-policy_checbox input:checked ~ .custom-control-label::before {
  border-color: #3fae29;
  background-color: #3fae29;
}
.c-confirm-policy_checbox .custom-checkbox input:checked ~ .custom-control-label::after {
  background-image: url("../images/check-white.svg");
}
.c-confirm-policy a {
  color: #3eae29;
  text-decoration: underline;
}
.c-confirm-policy_text {
  line-height: 1.2;
  font-size: 18px;
}
.c-confirm-action {
  margin-top: 40px;
  text-align: center;
}

/*Sucessfully Page*/
.c-successful-service {
  padding-bottom: 30px;
}
.c-successful-service__img {
  margin-top: 50px;
}
.c-successful-service__title {
  margin-top: 30px;
  font-size: 30px;
  font-family: var(--font-robo-b);
}
.c-successful-service__text {
  margin-top: 22px;
  font-size: 18px;
  padding: 0 30px;
}
.c-successful-service__text > span {
  color: #a1a1a1;
}
.c-successful-service__content {
  margin-top: 22px;
  font-size: 18px;
  padding: 0 30px;
}
.c-successful-service__link {
  margin-top: 22px;
  font-family: var(--font-robo-m);
  font-size: 18px;
  padding: 0 52px;
  color: #3eae29 !important;
  display: block;
}
.c-successful-service__btn {
  margin-top: 40px;
}

.c-successful-remote__img {
  margin-top: 143px;
}
.c-successful-remote__title {
  margin-top: 30px;
  font-size: 30px;
  font-family: var(--font-robo-b);
  padding: 0 20px;
}
.c-successful-remote__content {
  margin-top: 20px;
  font-size: 18px;
  padding: 0 52px;
}
.c-successful-remote__btn {
  margin-top: 50px;
}

.c-successful-driver__contaner {
  padding: 64px 20px 30px 20px;
  background-image: linear-gradient(to bottom, #02751a, #ffffff);
}
.c-successful-driver__block {
  border-radius: 6px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  max-width: 364px;
  margin: auto;
  position: relative;
  padding-bottom: 24px;
}
.c-successful-driver__img {
  left: calc(50% - 35px);
  position: absolute;
  top: -36px;
}
.c-successful-driver__title {
  padding: 64px 24px 0 24px;
  font-size: 30px;
  font-family: var(--font-robo-b);
}
.c-successful-driver__code {
  color: #0e730f;
  font-size: 30px;
  font-family: var(--font-robo-b);
}
.c-successful-driver__text {
  font-size: 18px;
  padding: 20px 42px 0 42px;
}
.c-successful-driver__content {
  margin: 50px 52px 0 52px;
  font-size: 18px;
}
.c-successful-driver__btn {
  margin: 20px 0px;
}
.c-successful-driver__link {
  margin-top: 10px;
  position: relative;
  display: inline-block;
}
.c-successful-driver__link span {
  color: #3eae29;
  font-size: 18px;
  font-family: var(--font-robo-m);
  text-decoration: underline;
  cursor: pointer;
}
.c-successful-copy {
  min-width: 85px;
  height: 34px;
  background: #ffdb00;
  color: var(--color-green-d);
  font-size: 15px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  border-radius: 4px;
  padding: 7px 12px;
  box-sizing: border-box;
  font-family: "Roboto-Medium";
  transition: all 0.2s;
  animation: moveTop 0.2s;
  animation-fill-mode: both;
  z-index: 2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 100%;
  white-space: nowrap;
}
.c-successful-copy::before {
  content: "";
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #ffdb00;
  position: absolute;
  bottom: -6px;
  left: calc(50% - 7px);
}

/* c-block */
.c-block-container {
  display: flex;
  flex-flow: row wrap;
}
.c-block {
  position: relative;
  width: 100%;
  padding: 10px 20px;
  margin-bottom: unset;
}
.c-block-title {
  width: 100%;
  background-color: #f1f1f1;
  padding: 10px 20px;
  font-family: var(--font-robo-m);
  color: #a1a1a1;
  margin: 10px 0px;
}
.c-block-input {
  height: 45px;
  padding-left: 10px;
  border-color: #a6a6a6;
  -webkit-appearance: none;
}
.error .c-block-input,
.error .c-section-textarea,
.error.c-section-upload,
.c-block-invalid__error ~ .c-block-input,
.c-block-invalid__error ~ .c-section-textarea {
  background-color: var(--color-red-l);
  border-color: var(--color-red);
}
.c-block label {
  color: var(--color-gray);
  font-family: var(--font-robo-m);
  margin-bottom: 10px;
}
.c-block .c-block__group-title {
  margin: 0;
  color: var(--color-gray);
  font-family: var(--font-robo-m);
  font-size: 20px;
}
.c-block .c-block-invalid {
  margin: -5px 0 5px 0;
}
.c-block-select_icon {
  display: flex;
  height: 41px;
  width: 41px;
  position: absolute;
  bottom: 2px;
  right: 2px;
}
.c-block-select_icon img {
  width: 18px;
  object-fit: contain;
  margin: auto;
}
.c-block-invalid {
  color: var(--color-red);
  display: flex;
  font-size: 13px;
}

.c-block-bank {
  margin: 0 0 1.5rem 0;
  color: #444;
  text-align: center;
}

.c-block-bank-title {
  margin-bottom: 0.5rem;
  color: #444;
  font-size: 1.25rem;
  font-family: var(--font-robo-b), sans-serif;
}
.c-block-bank-description {
  margin: 0 auto;
  text-align: left;
  padding: 0!important;
}

/*Complete Application*/
.c-mainview-complete {
}
.c-mainview-complete .c-intro-DLVR {
}
.c-complete-title {
  color: #a1a1a1;
  font-size: 18px;
  font-style: italic;
  padding: 20px 20px 10px 20px;
}
.c-complete-title > span {
  color: #ce1126;
}
.c-section-recaptcha {
  margin-bottom: 10px;
}
/* Section*/
.c-mainview-section {
  color: #444440;
  display: flex;
  flex-direction: column;
}
.c-section-title {
  font-size: 27px;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-bottom: 1px solid #979797;
  background-color: #ececec;
  cursor: pointer;
}
.c-section-title__error {
  color: var(--color-red);
}
.c-section-title span {
  flex: 1;
}
.c-section-block {
  color: #444440;
  padding-bottom: 10px;
  border-bottom: 1px solid #979797;
}
.c-section-group_title {
  font-size: 20px;
  font-family: "Roboto-Bold";
  color: #3fae29;
  margin-bottom: 10px;
}
.c-section-upload {
  height: 140px;
  width: 140px;
  text-align: center;
  background-color: #ececec;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.c-section-upload.c-section-upload--error {
  border: 1px solid #ce1126;
  color: #f44336;
  background-color: #f5cfd4;
}
.c-section-upload.isDisabled .c-section-upload_image {
  opacity: 0.5;
}
.c-section-upload_image {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}
.c-section-upload_text {
  font-size: 14px;
  padding: 0 15px;
  line-height: 1.1;
  margin-top: 10px;
}
.c-section-upload_edit {
  position: absolute;
  bottom: 0;
  left: 0.5px;
  right: 0;

  padding: 3px 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
}
.c-section-upload_overlay {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.error .c-section-upload,
.c-block-invalid__error ~ .c-section-upload {
  background-color: #f8dedd;
  border: 1px solid var(--color-red);
}
.error .c-section-upload_text,
.error .c-section-upload > img,
.c-block-invalid__error ~ .c-section-upload_text,
.c-block-invalid__error ~ .c-section-upload > img {
  opacity: 0.5;
}
.c-section-phone_img {
  height: 20px;
  object-fit: contain;
  margin: 0 5px 2px 0;
}

.c-mainview textarea.c-section-textarea {
  min-height: 89px;
  height: 100%;
  max-height: 138px;
  border-color: #a6a6a6;
  resize: none;
  padding: 10px 10px 5px 10px;
}
.c-section-phone_description {
  color: #a1a1a1;
  font-style: italic;
  margin-top: 14px;
  font-size: 14px;
}
.c-section-phone_checked {
  object-fit: contain;
  position: absolute;
  top: 7px;
  right: 10px;
}
.error.c-section-phone .c-section-phone_text,
.error.c-section-phone input,
.c-section-phone .c-block-invalid__error ~ .input-group .c-section-phone_text,
.c-section-phone .c-block-invalid__error ~ .input-group input {
  border-color: var(--color-red);
}
.error.c-section-phone input,
.c-section-phone .c-block-invalid__error ~ .input-group input {
  background-color: #fdd9d7;
}
.c-section-phone .input-group .c-block-input {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.c-section-email_opt {
  color: #444440;
  font-size: 16px;
  top: -3px;
}
.c-section-phone_text {
  padding-left: 5px;
  padding-right: 5px;
}
.section-agreements {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
.section-agreements-block {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.section-agreements-block_content {
  width: 100px;
  height: 100px;
  border-radius: 4px;
  background-color: #eaeaea;
  border: 1.6px dotted #a1a1a1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  font-family: "Roboto-Medium";
  font-size: 18px;
  line-height: 1.2;
}
.agreements-readed .section-agreements-block_content {
  background-color: #d9efd4;
  border-color: #4aae5e;
}
.agreements-to-read .section-agreements-block_content {
  border: 1.6px dotted #ce1126;
  background-color: #f5cfd4;
  border-color: #ce1126;
}
.archive .section-agreements-block_content {
  background-color: #ececec;
  border-color: #a1a1a1;
}
.signed .section-agreements-block_content {
  background-color: #ececec;
  border-color: #a1a1a1;
}
.section-agreements-block_content > img {
  margin: 10px 0;
}
.section-agreements-block_text {
  width: 100px;
  text-align: left;
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 10px;
}
.c-section-agreements_text {
  color: #a1a1a1;
  font-family: "Roboto-Regular";
  font-size: 14px;
  padding: 0 20px;
  font-style: italic;
}
.agreements-readed.section-agreements-block {
  color: #3fae29;
}
.agreements-to-read.section-agreements-block {
  color: #ce1126;
}

.c-block-input_unit {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
  padding-right: 45px;
}
.c-section-input_unit {
  position: absolute;
  top: 2px;
  bottom: 2px;
  right: 2px;
  z-index: 99;
}
.c-section-input_unit > .input-group-text {
  background-color: white;
  border: none;
}
.c-section-license .custom-control-label {
  color: unset;
  padding-left: 5px;
  font-size: 16px;
}
.c-section-license .custom-control-label::before,
.c-section-license .custom-control-label::after,
.c-section-license_checkbox .custom-control-label::before,
.c-section-license_checkbox .custom-control-label::after {
  width: 1.2rem;
  height: 1.2rem;
}
.c-section-license .custom-control-input:checked ~ .custom-control-label::after {
  filter: invert(55%) sepia(83%) saturate(424%) hue-rotate(64deg) brightness(104%) contrast(98%);
  background-size: 70%;
}
.c-section-license .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #a6a6a6;
  background-color: #fff;
}
.c-section-license_checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3fae29;
  color: #3fae29;
}
.c-section-eye_icon {
  cursor: pointer;
}

.c-section-message_saved {
  position: fixed;
  top: 55px;
  left: 0;
  right: 0;
  padding: 10px 20px;
  color: #fff;
  background: #247215;
  font-size: 14px;
  z-index: 1;
}
.c-section-message_error {
  position: fixed;
  top: 55px;
  left: 0;
  right: 0;
  z-index: 1;

  padding: 10px 20px;
  color: #fff;
  background: #ce1126;
  font-size: 14px;
}

/* react-pdf */
.react-pdf__Page {
  box-shadow: 0px 0px 20px #aaa;
  margin: 30px auto;
  max-width: 700px;
}
.react-pdf__Page .react-pdf__Page__svg,
.react-pdf__Page svg,
.react-pdf__Page > img {
  width: 100% !important;
  height: unset !important;
}
.react-pdf__Signature {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 2;
}
.react-pdf__Page__textContent ::selection {
  background: rgb(0, 0, 255);
}
.title-label-container {
  color: #444;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
  padding-bottom: 10px;
}
.popup-title-text {
  color: #40AE29;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  position: absolute;
  right: 0;
  top: 2px;
  cursor: pointer;
}
.btn-social .btn-social-1.btn-term .i-term-hover {
  display: none;
}

/* Button */
.Button {
  padding: 0 20px;
}
.Button button {
  font-size: 22px;
  width: 100%;
  max-width: 384px;
  height: 54px;
  background-color: var(--color-green-l);
  border-radius: 4px;
  color: var(--color-white);
  border: none;
  font-family: var(--font-robo-b);
}
.Button button:disabled {
  opacity: 0.5;
}

/* React Select */
.react-select .react-select__single-value {
  width: 100%;
}

.c-successful-service__btn--later {
  margin-top: 10px;
}
.c-successful-service__btn--later .spinner-border-sm {
  margin-right: 0.5rem;
  border-width: 0.2rem;
  vertical-align: middle;
}
.c-successful-service__btn--later button {
  border: 1px solid #a1a1a1;
  background-color: #fff;
  color: #282828;
}
