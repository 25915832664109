/*
 * Config Zoom
 */

@media (min-width: 767px) {
  .mobile-screen {
    transform: scale(0.88);
    margin-top: calc(-100% * 0.06);
    margin-bottom: calc(-100% * 0.06);
  }
}
@media (min-width: 768px) and (max-width: 1367px) {
  body {
    transform: scale(var(--scale-standard));
    transform-origin: 0 0;
    width: calc(100% / var(--scale-standard));
    height: calc(100% / var(--scale-standard));
  }
  body .mobile-screen {
    transform: scale(0.98);
    margin-top: calc(-100% * 0.01);
    margin-bottom: calc(-100% * 0.01);
  }
}

/*
 * Responsive
 */

/* mobile */
@media (max-width: 767px) {
  .none767 {
    display: none !important;
  }
  .block767 {
    display: block !important;
  }
  .Modal.visible.page,
  .Modal.visible.full {
    left: 0;
    z-index: 9999;
  }
  body {
    transform: unset;
    transform-origin: unset;
    box-sizing: border-box;
  }
  body .mobile-screen {
    max-width: 100%;
    min-width: 320px;
    width: 100%;
    height: 100%;
    transform: none;
    margin-top: unset;
    margin-bottom: unset;
  }
  .c-mainview {
    overflow-x: hidden;
    font-size: 30px;
  }
  .c-mainviewfull {
    font-size: 30px;
  }
  .l-container {
    height: calc(100 * var(--vh));
  }
  .c-mainview-complete,
  .c-mainview-section {
    font-size: 16px;
  }
  .c-section-title {
    height: 60px;
    font-size: 25px;
  }
  .l-content-footer button {
    font-size: 17px;
  }
  .react-pdf__Signature {
    max-width: 27%;
  }

  .l-content-footer_finish .btn-secondary:hover {
    background-color: var(--color-white);
    color: var(--color-green-l);
    border: 2px solid var(--color-green-l);
  }
  .l-content-footer_finish .btn-secondary:active {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
  }
  .c-login-description {
    margin-top: 5rem;
  }
  .c-login-description h2 {
    padding: 0;
  }
  .c-login-approval {
    font-size: 18px;
    margin-top: 9vh;
  }

  /* confirm page */
  .c-confirm-img {
    margin-top: 6vh;
  }
  .c-confirm-policy {
    margin-top: 10vh;
  }

  /* successful */
  .c-successful-driver__contaner {
    padding: 50px 20px 30px 20px;
  }
  .c-successful-driver__content {
    margin: 6vh 52px 0 52px;
  }
  .c-successful-service__img {
    margin-top: 10vh;
  }

  /*  sign_in */
  .c-verify-text {
    padding: 28px 52px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: unset;
  }
}
@media (max-width: 359px) {
  /* confirm page */
  .c-confirmtion-description {
    font-size: 19px;
    padding: 0 20px;
  }
  .c-confirm-img img {
    height: 82px;
  }
  .c-confirm-policy {
    margin-top: 7vh;
  }
  .c-confirm-action {
    margin-top: 32px;
  }
  .c-confirm-action.Button button {
    height: 50px;
  }

  /* successful */
  .c-successful-service__text {
    padding: 0 30px;
  }
  .c-successful-service__content {
    padding: 0 30px;
  }
  .c-successful-driver__text {
    padding: 15px;
  }
  .c-successful-driver__content {
    margin: 2vh 20px 0 20px;
  }
  .c-successful-driver__btn.Button {
    padding-bottom: 10px;
  }
  .c-successful-remote__img {
    margin-top: 15vh;
  }

  /* sigin_in */
  .c-verify-text {
    padding: 28px 30px;
  }
  .c-verify-card_box > span {
    width: 42px;
    height: 42px;
    line-height: 42px;
  }
}

/*
 * Deliveree Onboarding Style
 */

/* Login */
@media (min-width: 1368px) {
  .c-mainview-login {
    min-height: 720px;
    max-height: 720px;
  }
}
